import React from "react";

import { Container, Row, Col, Card, Button } from "reactstrap";

function SpeakerCard({ speaker, index, setOverlay, overlay }) {
  const size =
    document.body.clientWidth >= 1024 ||
    document.documentElement.clientWidth >= 1024
      ? "21rem"
      : "25rem";
  return (
    <Card
      style={{
        maxWidth: "none",
        height: size ? "28rem" : "24rem",
      }}
      onMouseEnter={() => setOverlay([speaker, index, true])}
      onMouseLeave={() => setOverlay([speaker, index, false])}
    >
      <Container fluid className='p-0'>
        <img
          alt='...'
          className='img-rounded img-responsive'
          width='100%'
          src={require(`assets/images/speakers/${speaker.image}`)}
        />
      </Container>
      <Container
        fluid
        style={{
          borderRadius: "0 0 10px 10px",
          background: "#fff",
          zIndex: 10,
          minHeight: "100px",
          position: "absolute",
          bottom: 0,
        }}
      >
        <Row>
          {speaker.companyImage ? (
            <>
              <Col xs={8} className='mt-2'>
                <h6
                  className='d-inline text-i'
                  style={{ fontSize: size ? "1.2rem" : "0.9rem" }}
                >
                  {" "}
                  <b> {speaker.name}</b>
                </h6>
              </Col>
              <Col xs={4} className='p-0'>
                <img
                  alt='...'
                  className='img-circle border border-warning img-no-padding img-responsive'
                  src={require(`assets/images/speakers/${speaker.companyImage}`)}
                  width='80rem'
                  style={{
                    marginTop: "-40px",
                  }}
                />
              </Col>
            </>
          ) : (
            <Col xs={12} className='mt-2 text-center'>
              <h6
                className='d-inline text-i'
                style={{ fontSize: size ? "1.2rem" : "0.9rem" }}
              >
                <b> {speaker.name}</b>
              </h6>
            </Col>
          )}
        </Row>
        <Row>
          <Col lg={12} className='p-0 text-dark'>
            {size ? (
              <h5 className='m-2 text-center text-400'>
                {speaker.title}
                <br />
                {speaker.company && (
                  <span className='p-1 text-700'>{speaker.company}</span>
                )}
              </h5>
            ) : (
              <h6 className='m-2 text-center text-300'>
                {speaker.title}
                <br />
                {speaker.company && (
                  <span className='p-1 text-700'>{speaker.company}</span>
                )}
              </h6>
            )}
          </Col>
        </Row>
      </Container>
    </Card>
  );
}

function SpeakerCardMobile({ speaker, index, setOverlay, overlay }) {
  const size = "23rem";
  return (
    <Card
      style={{
        maxWidth: "none",
        height: "17rem",
      }}
      className='rounded-0'
    >
      <Container fluid className='p-0'>
        <img
          alt='...'
          className='img rounded-0 img-responsive'
          width='100%'
          src={require(`assets/images/speakers/${speaker.image}`)}
        />
      </Container>
      <Container style={{ textTransform: "none" }}>
        <Row>
          <Col xs={12} className='mt-2 px-1'>
            <h6
              className='d-inline text-i text-900'
              style={{ textTransform: "none" }}
            >
              {speaker.name}
            </h6>
          </Col>
        </Row>
        <Row>
          <Col lg={12} className='text-dark text-left px-1'>
            <h6
              className='mt-1 text-700'
              style={{ fontSize: "10px", textTransform: "none" }}
            >
              {speaker.title}
            </h6>
            <h6 className='text-700' style={{ textTransform: "none" }}>
              {speaker.company && <span>{speaker.company}</span>}
            </h6>
          </Col>
        </Row>
      </Container>
    </Card>
  );
}

function Speaker({ speakers }) {
  const size =
    document.body.clientWidth >= 1024 ||
    document.documentElement.clientWidth >= 1024
      ? true
      : false;
  const [overlay, setOverlay] = React.useState([speakers[0], 0, false]);
  return (
    <>
      <div
        style={{
          backgroundColor: "#000",
          overflow: "hidden",
        }}
        className='section'
      >
        <Container
          fluid
          style={{
            backgroundRepeat: "no-repeat",
            backgroundSize: "cover",
          }}
        >
          <Container>
            <Row>
              <Col lg={12} className={"mx-auto"}>
                <Container>
                  <Row>
                    <Col lg={12}>
                      <Container fluid>
                        <Row className='justify-content-center'>
                          {speakers.map((s, i) => (
                            <Col lg={4} md={6} xs={6} key={i} className='p-1'>
                              {size ? (
                                <SpeakerCard
                                  speaker={s}
                                  index={i}
                                  overlay={overlay}
                                  setOverlay={setOverlay}
                                />
                              ) : (
                                <SpeakerCardMobile
                                  speaker={s}
                                  index={i}
                                  overlay={overlay}
                                  setOverlay={setOverlay}
                                />
                              )}
                            </Col>
                          ))}
                        </Row>
                      </Container>
                    </Col>
                  </Row>
                </Container>
              </Col>
            </Row>
          </Container>
        </Container>
      </div>
    </>
  );
}

export default Speaker;
