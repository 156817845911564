import React from 'react';
import { Container, Row, Col, Button } from 'reactstrap';
import { FacebookShareButton, LinkedinShareButton, TwitterShareButton } from 'react-share';

function news({ news }) {
	const shareUrl = 'www.finnosec.com/finnoseclive/';
	return (
		<>
			<Container>
				<Row>
					<Col xs={12}>
						<div className="owner">
							<div className="avatar-big text-center">
								<img
									alt=""
									className="img-no-padding img-responsive img-rounded pt-2"
									src={require(`assets/images/news/${news.image}`)}
								/>
							</div>
						</div>
					</Col>
				</Row>

				<Row>
					<Col className={'ml-auto mr-auto my-2'} md="10">
						<h6>
							Source :{' '}
							<a href="https://www.finextra.com/blogposting/17493/is-banking-security-in-africa-holding-fintech-back">
								Stuart Jackson
							</a>
						</h6>
					</Col>
				</Row>
				<Row>
					<Col xs={1} className="d-none d-xl-block d-lg-block d-md-block"></Col>
					<FacebookShareButton
						url={`${shareUrl}${news.link}`}
						style={{ padding: 0, margin: 0, display: 'inline !important' }}
						children={
							<Button
								color="neutral"
								outline
								style={{
									padding: '0rem',
									marginRight: '30px',
									marginLeft: '15px',
								}}
							>
								<span style={{ color: '#3b5998', display: 'inline' }}>
									<i style={{ fontSize: '50px' }} className="fa fa-facebook-square float-left" />
								</span>
							</Button>
						}
					/>

					<LinkedinShareButton
						url={`${shareUrl}${news.link}`}
						style={{ padding: 0, margin: 0, display: 'inline !important' }}
						children={
							<Button
								color="neutral"
								outline
								style={{
									padding: '0rem',
									marginRight: '30px',
								}}
							>
								<span style={{ color: '#0976b4' }}>
									<i style={{ fontSize: '50px' }} className="fa fa-linkedin-square float-left" />
								</span>
							</Button>
						}
					/>
					<TwitterShareButton
						url={`${shareUrl}${news.link}`}
						style={{ padding: 0, margin: 0, display: 'inline !important' }}
						children={
							<Button
								color="neutral"
								outline
								style={{
									padding: '0rem',
									marginRight: '30px',
								}}
							>
								<span style={{ color: '#55acee' }}>
									<i style={{ fontSize: '50px' }} className="fa fa-twitter-square float-left" />
								</span>
							</Button>
						}
					/>
				</Row>

				<Row>
					<Col className={'ml-auto mr-auto text-justify'} md="10">
						<br />
						<p>
							Many people, including myself, are becoming more interested in what’s going on in African
							banking and fintech. Investors and infrastructure founders such as{' '}
							<a href="https://www.crunchbase.com/person/dana-buys">Dana Buys</a>,{' '}
							<a href="Mzi Khumalo">Mzi Khumalo</a>, and{' '}
							<a href="https://fintechng.org/president-dr-segun-aina/">Dr Segun Aina</a> have certainly
							played a role in turning attention towards this vibrant, exciting continent.
						</p>
						<p>
							But while we are focused on the opportunities and innovations coming out of Africa, is there
							a chance that we’re underplaying one of the key barriers to success for fintech?
						</p>
						<br />

						<h3>
							<b>Security is a real threat in African banking</b>
						</h3>

						<p>
							Last month, new figures released by the South African Banking Risk Information Centre
							(Sabric), revealed a huge spike in credit card fraud, up an eyewatering 18% year-on-year. In
							fact, the problem has got so big that Sabric estimates that Card Not Present (CNP) fraud
							accounts for nearly 80% of all fraud losses in South Africa. This is a very big figure
							indeed.
						</p>
						<br />
						<p>
							Most of this fraud was put down by the agency to bogus calls which make individuals think
							they’re talking to their bank, and, of course, phishing emails. The figures are in stark
							contrast to those for counterfeit card fraud, which has decreased some 32% over the last
							year.
						</p>
						<br />
						<p>
							What is clear is that fraudsters are becoming more sophisticated and are seeing the
							opportunities created by mobile banking in a country where, by 2020, 52% of the whole
							population, more than 600 million people in Africa, will have a mobile phone subscription.
						</p>
						<br />
						<p>
							Unsurprisingly given the huge uptake, recent reports suggest that there has been a
							noticeable increase in the numbers of organised hacking rings in Africa and that
							sophisticated cyber-crime is taking over from more traditional forms of fraud.
						</p>
						<br />
						<p>
							According to the Institute of Security Studies in Africa, data is now seen as the potential
							gateway to riches, so much so that it has encouraged crime syndicates to ‘sell’ themselves
							as hacking-service providers. Clearly, though this kind of threat is very real in the more
							developed markets of the west, the fact that it’s becoming apparent now in the world’s
							largest continent, should ring even louder alarm bells.
						</p>
						<br />
						<br />
						<h3>
							<b>Mobile banking fraud is on the rise</b>
						</h3>

						<p>
							There can be no doubt that mobile banking fraud is on the rise in Africa. But to understand
							the challenge it’s worth looking at just one high-profile case. In December last year three
							people were arrested in Kenyan for allegedly perpetrating a large-scale fraud on M-Pesa, one
							of the world’s most iconic fintechs.
						</p>
						<br />
						<p>
							It is alleged that they were part of a gang involved in a scam whereby they made calls to
							customers pretending to be employees of M-Pesa. If successful, they acquired PIN numbers and
							IDs, swapped SIM cards and could then transfer money out of that person’s account.
						</p>
						<br />
						<p>
							One of the people scammed by this kind of fraud was a Kenyan politician, Stanley Wanjiku,
							who lost around £14,000, and this kind of theft has become so commonplace that police in
							some regions of Kenya receive as many as five reports per day.
						</p>
						<br />
						<h3>
							<b>Fintechs are starting to fight back – but not fast enough</b>
						</h3>

						<p>
							Safaricom, the M-Pesa parent company, has rightly taken this kind of threat seriously, and
							just this month they announced the launch of an anti-fraud intelligence solution. Available
							for both traditional banks and fintechs, the solution aims to act as an alert system on
							potentially fraudulent transactions involving SIM card swaps and newly registered lines.
						</p>
						<br />

						<p>
							However, though the headlines tell a story of a big increase in mobile banking fraud, there
							appears to be a reticence by the main fintech players to develop effective solutions, or
							even tackle the problem with any urgency. This is no surprise given that traditional banks
							have underplayed the threats for years, but it does beg the question as to whether by
							brushing security under the carpet, they are encouraging fintechs to do the same.
						</p>
						<br />
						<p>
							If African fintechs are going to protect themselves and their customers they need to get
							smart about developing and embracing new security technologies. Across the west, we’re
							seeing a noticeable rise in the number of startups dedicated to creating fintech security
							solutions. If Africa is going to become a leading playing in mobile banking and reach its
							huge potential, it needs this kind of laser focus on fraud prevention to compete on the
							global stage.
						</p>
						<br />
					</Col>
				</Row>
			</Container>
		</>
	);
}

export default news;
