import React from "react";
import { Container, Row, Col, Button } from "reactstrap";
import {
  FacebookShareButton,
  LinkedinShareButton,
  TwitterShareButton,
} from "react-share";

function news({ news }) {
  const shareUrl = "www.finnovex.com/finnovexlive/";
  return (
    <>
      <Container>
        <Row className="py-4">
          <Col xs={6}>
            <div className="owner">
              <div className="avatar-big text-center">
                <img
                  alt=""
                  className="img-no-padding img-responsive img-rounded pt-2"
                  src={require(`assets/images/news/${news.image}`)}
                  width={"400px"}
                />
              </div>
            </div>
          </Col>
          <Col xs={6} className="align-self-center">
            <p>
              <strong>
                Mr Venkatesh is an international banker and Senior Risk
                Management executive spanning over three decades of astute
                business accumen. Seasoned enterprise risk management executive
                with strong foothold in Strategy, Credit, Market and Operational
                Risk along with Enterprise Risk Management. He brings to the
                table a high degree of emotional maturity and business
                intelligence. Keynote Speaker and a vivid writer on various Risk
                subject areas in International Risk Forums, a “C-Suite”
                leadership coach and mentor with accomplished experience across
                Banking, and Financial Services.
                <br />
                <br />
                MR Venkatesh Kallur will be speaking virtually at Finnosec ME
                Virtual Summit. Please have a look at his view on our upcoming
                The Leading Summit on Financial Security and Cyber Resilience
                held Virtually on 17- 18 March 2021 ( Attend from anywhere 💻🖥📱
                )
              </strong>
            </p>
          </Col>
        </Row>

        <Row>
          <Col xs={1} className="d-none d-xl-block d-lg-block d-md-block"></Col>
          <FacebookShareButton
            url={`${shareUrl}${news.link}`}
            style={{ padding: 0, margin: 0, display: "inline !important" }}
            children={
              <Button
                color="neutral"
                outline
                style={{
                  padding: "0rem",
                  marginRight: "30px",
                  marginLeft: "15px",
                }}
              >
                <span style={{ color: "#3b5998", display: "inline" }}>
                  <i
                    style={{ fontSize: "50px" }}
                    className="fa fa-facebook-square float-left"
                  />
                </span>
              </Button>
            }
          />

          <LinkedinShareButton
            url={`${shareUrl}${news.link}`}
            style={{ padding: 0, margin: 0, display: "inline !important" }}
            children={
              <Button
                color="neutral"
                outline
                style={{
                  padding: "0rem",
                  marginRight: "30px",
                }}
              >
                <span style={{ color: "#0976b4" }}>
                  <i
                    style={{ fontSize: "50px" }}
                    className="fa fa-linkedin-square float-left"
                  />
                </span>
              </Button>
            }
          />
          <TwitterShareButton
            url={`${shareUrl}${news.link}`}
            style={{ padding: 0, margin: 0, display: "inline !important" }}
            children={
              <Button
                color="neutral"
                outline
                style={{
                  padding: "0rem",
                  marginRight: "30px",
                }}
              >
                <span style={{ color: "#55acee" }}>
                  <i
                    style={{ fontSize: "50px" }}
                    className="fa fa-twitter-square float-left"
                  />
                </span>
              </Button>
            }
          />
        </Row>

        <Row>
          <Col className={"ml-auto mr-auto text-justify"} md="10">
            <br />
            <br />
            <p>
              <strong>
                Q- As Cyber threats has evolved rapidly, can you describe how
                this currently challenges the era of digital banking?
              </strong>
            </p>

            <p>
              As Banking industry is moving into digitization and taking the
              banking transactions into the hands of our customers, the cyber
              threats are increasing, as a result ethical hacking is a job by
              itself within Information Security, whose job is day in and day
              out, hack our own infrastructure and fix the weaknesses. Banks
              need to invest heavily within the information security domain in
              order to successfully enter and embrace the era of digital
              banking. Certainly, digital banking provides several cost savings
              along with time to market for customers.
            </p>
            <br />
            <br />
            <p>
              <strong>
                Q- What are the roles of different stakeholders like regulators
                solution providers and financial institution in steering up the
                regions readiness in becoming an overall secure economy?
              </strong>
            </p>

            <p>
              Handling the cyber security and come up with forward looking
              regulations and guidelines with respect to information security
              and accordingly the financial institutions will fall in line, as
              per regulator’s guidelines and industry best practices. The
              solution providers have a big role to play as they have a bird’s
              eye view of global and International practices and accordingly,
              they should also take the lead and bring such sophisticated
              frameworks into the region through awareness sessions and active
              campaign management.
            </p>
            <br />
            <br />
            <p>
              <strong>
                Q- Can you highlight how some the role different stakeholders
                like regulators solution providers and financial institution
                play is slowing down the pace of the regions readiness in
                becoming a secure economy?
              </strong>
            </p>

            <p>
              As I said before, there is nothing like a secured economy. If
              things elicited in the above question are not embraced in a
              holistic manner by regulators, solution providers and financial
              institutions the regions readiness to effectively handle
              information security threats becomes a long dream rather than
              reality. Having said this, I see a lot of traction in the region
              be it from regulators, solution providers or banking institutions.
              This momentum needs to be maintained in order to create a
              reasonably secured economy factoring in “Risk vs. Reward”.
            </p>
            <br />
            <br />
            <p>
              <strong>
                Q- Speaking of post-COVID, what steps should be taken by banks
                to build up against cyber threats? Who should be more proactive
                and what is at stake if this is not enforced?
              </strong>
            </p>

            <p>
              The only positive outcome of COVID-19 is, it has taught the
              marketplace businesses can be run remotely through “Management by
              Objective” framework. As remote working, Working from Home (WFH)
              became the new norm of the business model in the region, it should
              extend this practice beyond COVID-19 and use effectively to bring
              down the operational expenses like utilities, real estate rentals,
              contribute to the greener world by reducing the vehicle pollution,
              improve the traffic congestion in the cities, etc. As more and
              more employees and institutions are going to work remotely, this
              puts additional pressure on IT infrastructure in terms of several
              Information Security risks that emanate, as hackers would like to
              use this to their advantage. In the past I have witnessed in North
              America enormous number of investments in the security (both
              physical and IT), post 9/11. Similarly, post COVID-19 will unleash
              several investments in the information security domain, due to
              several vulnerabilities in the infrastructure clubbed with
              proactive new regulations from regulators and industry best
              practices, organizations need to tighten their belts and make
              judicious investments. The stakes are high for financial industry
              to the extent, it can wipe out the balance sheet, if IT
              infrastructure is not robust with regular testing for
              vulnerabilities, DDOS attacks along with Phishing and spoofing
              attacks.
            </p>
            <br />
            <br />
            <p>
              <strong>
                Q- What active steps would you take within your organisation
                towards building up a Cyber Resilient and Digitally Empowered
                Economy?
              </strong>
            </p>

            <p>
              I have taken several initiatives within ISO area, in terms of
              increasing the headcount to tackle the new workload and new
              threats, enhance the infrastructure. Increase the SIEM and SOC
              infrastructure. Make sure, ISO and Operational Risk is inherent
              part of all new projects, so that at the project stage itself
              cover all the potential risks the organization may face due to the
              launch of new initiatives. We even started actively monitoring all
              senior executives of the Bank for Phishing and Spoofing attacks.
              Further, the following steps are implemented in my organization to
              create cyber resilience and a digitally empowered economy:
              <br />
              <ul>
                <li>
                  We have a proactive and systematic process for managing
                  standard systems hygiene: These are the normal business as
                  usual processes such as vulnerability detection, system
                  security patching, having the latest antivirus signatures and
                  running an identity management system etc.
                </li>
                <li>
                  A cross functional team is available with Senior Management to
                  plan for cyber security events and consider hypothetical
                  attacks: This is part of our incident management process and
                  is crucial for identifying threats based on theoretical
                  scenarios.
                </li>
                <li>
                  Cyber patterns and attack modes are studied to develop a
                  tailored approach to protecting the Bank’s assets: Each
                  organization in the BFSI sector has its own unique
                  infrastructure and processes. Having a customized security
                  approach for your organization will maximize the protection of
                  your assets and reduce the risk of a data breach or denial of
                  services.
                </li>
                <li>
                  Mitigating risks by investing time, resources and money to
                  protect assets at a higher risk: Depending on the risk
                  assessment being done. Sometimes investing in mitigating risks
                  will cost much less than accepting the risk. A cost benefit
                  analysis is important here. It is however mandatory to
                  mitigate risks relating to customer information.
                </li>
                <li>
                  {" "}
                  Cyber insurance: We renew our cyber insurance policy on a
                  yearly basis. Transferring the risk of a financial loss due to
                  cyber-attacks is very important as this effects on our P&amp;L
                  will be much less than taking the burden of the whole loss. I
                  always push all organizations in the BFSI sector to purchase
                  the best cyber insurance available in the market.
                </li>
              </ul>
            </p>
            <br />
            <br />
            <p>
              <strong>
                Q- What proactive steps can stakeholders within the BFSI sector
                do to stay ahead when handling cyber security threats in the
                digital age?
              </strong>
            </p>

            <p>
              Having a security mindset is the first step in managing cyber
              security. The following proactive steps should be taken in the
              BFSI sector when handling cyber threats:
            </p>
            <br />
            <ul>
              <li>
                <b>Threat hunting</b>: This puts the organization on the
                offensive. Once cyber threats are identified, defensive and
                preventative controls can be put into place to make an attack
                difficult or impossible. Examples of threat hunting include
                identifying impersonating websites, sources of denial-of-service
                attacks and blocking of any indicators of compromise as reported
                by threat intelligence feeds.
              </li>
              <li>
                <b>Ethical hacking</b>: This type of hacking performs actual
                attacks with the intention to help identify a network’s
                weaknesses by exposing them. These “white hat” hackers use a
                variety of methods, such as social engineering and utilizing
                their own hacking software. Once the weaknesses are identified,
                they are mitigated so that if an actual attack happens, these
                weaknesses cannot be exploited.
              </li>
              <li>
                <b>Proactive network and endpoint monitoring</b>:  Variety of
                tools can be used here. A Security Information and Events
                Management system is the basis of proactive network monitoring.
                For the BFSI sector, the network must be monitored 24/7.
                Endpoint monitoring is another important component of this
                strategy. This involves monitoring the security of remote
                devices with access to business accounts, such as smartphones,
                tablets, desktop computers, laptops, and servers. Endpoints are
                often the easiest way for a hacker to gain access to your
                network.
              </li>
              <li>
                <b>Training and awareness</b>: Almost 90% of cyber-attacks are
                caused by human error or behaviour. All members of your staff,
                not just your IT department, should therefore be trained in
                security precautions. Everyone at the BFSI sector needs to be
                taught how to create strong passwords, report and delete
                suspicious emails, use a VPN if they need to access company data
                on a personal phone, and more.
              </li>
            </ul>
            <br />
            <br />
            <p>
              <strong>
                Q- Share any advice you would you like to share with your
                industry peers and banking professionals who are looking forward
                to attending this conference?
              </strong>
            </p>
            <p>
              When it comes to cyber security and digital transformation, it is
              vital to be one step ahead of cyber attackers and their threats.
              To achieve this, having strict security governance for all
              organizations in the BFSI sector is important. Creating security
              governance is achieved by having an Information Security Program
              that covers polices, standard operating procedures, guidelines,
              risk management, security awareness and having the appropriate
              cyber tools in place to mitigate the risks identified. Last but
              most important, staff your ISO with right bandwidth and talent.
            </p>
            <br />
          </Col>
        </Row>
      </Container>
    </>
  );
}

export default news;
