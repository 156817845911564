import React from "react";
import { Container, Row, Col } from "reactstrap";
import ScrollAnimation from "react-animate-on-scroll";
import Particles from "./Particles";
import Matrix from "./Matrix";

function Event() {
  const Text = ({ grid, title, description1, description2 }) => {
    return (
      <Col
        lg={grid}
        xs={12}
        className='py-3'
        style={{
          backgroundColor: "transparent",
          display: "flex",
          alignItems: "center",
        }}
      >
        <ScrollAnimation
          animateIn={"fadeInUp"}
          animateOnce={true}
          duration={1.5}
        >
          <Container>
            <Row>
              <h1
                className={"m-0 text-700 text-g"}
                style={{ fontSize: "3.4rem" }}
              >
                <b>{title}</b>
              </h1>
              <h6
                className={"m-0 text-white pl-2 ml-4"}
                style={{ borderLeft: "5px dotted #fdc031" }}
              >
                <p>{description1}</p>

                <p className='text-700 text-white pl-2'>{description2}</p>
              </h6>
            </Row>
          </Container>
        </ScrollAnimation>
      </Col>
    );
  };

  return (
    <div
      className='section'
      style={{
        backgroundColor: "#231f20",
        padding: "10px 0",
      }}
      data-parallax={true}
    >
      <Matrix
        fullscreen={true}
        height={2000}
        width={1000}
        style={{
          position: "absolute",
          display: "block",
          width: "100%",
          height: "100%",
          top: 0,
          left: 0,
          right: 0,
          bottom: 0,
          background: "#231f20",
          // overflow: 'hidden',
          zIndex: 0,
        }}
      />
      <Container>
        <Row className='justify-content-md-left'>
          <Col
            lg={12}
            style={{
              backgroundColor: "transparent",
              padding: "2rem 2rem",
              display: "flex",
              alignItems: "center",
            }}
          >
            <ScrollAnimation
              animateIn={"fadeInUp"}
              animateOnce={true}
              duration={0.8}
            >
              <Container>
                <Row>
                  <span>
                    <h1 className='text-700 text-g'>
                      THE LATEST LURES AND ATTACKS REVOLVING AROUND COVID-19
                    </h1>
                  </span>
                </Row>
              </Container>
            </ScrollAnimation>
          </Col>

          <Text
            grid={6}
            title='2444 Times'
            description1=''
            description2='Hackers attack once in every 39 seconds'
            color='#fff'
          />
          <Text
            grid={6}
            title='$1183'
            description1=''
            description2='The Global Average Cybersecurity Spend is $1183 per employee'
            color='#fff'
          />
          <Text
            grid={6}
            title='94%'
            description1=''
            description2='94% of Malware is delivered through emails'
            color='#fff'
          />
          {/* <Col lg={4}></Col> */}
          <Text
            grid={6}
            title='$ 3.9M'
            description1=''
            description2='The average cost of a data breach'
            color='#fff'
          />
          {/* <Col lg={2}></Col> */}
          <Text
            grid={6}
            title='56%'
            description1=''
            description2='of IT decision-makers believe phishing attacks are their top security threat'
            color='#fff'
          />
          <Text
            grid={6}
            title='11%'
            description1=''
            description2='Security breaches have increased by 11% since 2018'
            color='#fff'
          />

          <Text
            grid={6}
            title='69%'
            description1=''
            description2='Financial Services reported that they are 69% extremely concerned about cyber attacks'
            color='#fff'
          />
          <Text
            grid={6}
            title='15%'
            description1=''
            description2='The average cost of lost data records in Saudi Arabia and UAE around 15% more $160 than global average cost'
            color='#fff'
          />
        </Row>
        {/* <div
          style={{
            position: "absolute",
            bottom: 0,
            right: 10,
            fontSize: "10px",
          }}
        >
          <h6 className="text-white">
            *Source: Accenture – Cost of Cybercrime; Security Intelligence
            <br /> Cost of Data Breach; Symantec; Forbes; Varonis
          </h6>
        </div> */}
      </Container>
    </div>
  );
}

export default Event;
