import React, { useState } from "react";

import { Container, Row, Col, ButtonGroup, Button } from "reactstrap";

function Speaker({ speakers }) {
  const [step, setStep] = useState(1);
  const size =
    document.body.clientWidth >= 1024 ||
    document.documentElement.clientWidth >= 1024
      ? true
      : false;
  return (
    <>
      <div
        style={{
          backgroundColor: "#231f20",
          overflow: "hidden",
        }}
        className='section pt-0'
      >
        <img
          src={require(`assets/images/handshake.jpg`)}
          width='100%'
          alt='icon'
        />
        <Container
          fluid
          style={{
            backgroundImage:
              "url(" + require("assets/images/graphic23.png") + ")",
            backgroundAttachment: "fixed",
            backgroundRepeat: "no-repeat",
            backgroundSize: "cover",
          }}
        >
          <Container className='py-5'>
            <Row>
              <Col lg={6}>
                <h1 className='text-700 text-g'>SPONSORSHIP</h1>
                <h2 className='text-400 mt-0 text-g mb-3'>
                  <i>OPPORTUNITIES</i>
                </h2>
                <p className='text-400 text-white'>
                  If you are launching a new product or solution aligned with
                  one of the themes below, or have a proven track record in this
                  area with experience to share - we want to hear from you:
                </p>
              </Col>
              <Col lg={6}>
                <h1 className='text-700 text-g'>SPONSORSHIP</h1>
                <h2 className='text-400 mt-0 text-g mb3'>
                  <i>BENEFITS</i>
                </h2>
                <p className='text-400 text-white'>
                  Sponsorship at FinnoSec Middle East comes in many shapes and
                  sizes. We’ll enhance your experience by customizing and
                  helping you choose the option that’s right for you and your
                  brand. Our end goal is to put your brand in the spotlight and
                  above competition. We follow a simple{" "}
                  <span className='text-g'>3-Step process</span>.
                </p>
              </Col>
            </Row>
            <Row className='mt-5'>
              <Col lg={2}>
                <ButtonGroup vertical={size}>
                  <Button
                    onClick={() => setStep(1)}
                    className='btn my-1 text-center px-4'
                    color='primary'
                    size='lg'
                    active={step === 1}
                    outline
                  >
                    STEP 1
                  </Button>
                  <Button
                    onClick={() => setStep(2)}
                    className='btn my-1 text-center px-4'
                    color='primary'
                    size='lg'
                    active={step === 2}
                    outline
                  >
                    STEP 2
                  </Button>
                  <Button
                    onClick={() => setStep(3)}
                    className='btn my-1 text-center px-4'
                    color='primary'
                    size='lg'
                    outline
                    active={step === 3}
                  >
                    STEP 3
                  </Button>
                </ButtonGroup>
              </Col>
              <Col lg={7}>
                {step === 1 && <Step1 />}
                {step === 2 && <Step2 />}
                {step === 3 && <Step3 />}
              </Col>
            </Row>
          </Container>
          <Container>
            <Row>
              <Col lg={12} className='mt-5 text-center bg-white py-3'>
                <h3
                  className='text-center text-primary mt-0 text-700'
                  style={{ color: "#003972" }}
                >
                  DO YOU HAVE A PRODUCT OR SERVICE THAT OUR SENIOR
                  DECISION-MAKERS AND INFLUENCERS NEED?
                </h3>
                <br />

                <p className='text-300 mt-0'>
                  Sponsorship puts your brand in the spotlight. For more
                  information, write to us{" "}
                  <a href='mailto:info@exibex.com' className='text-primary'>
                    info@exibex.com
                  </a>{" "}
                  ! or call us on{" "}
                  <a href='tel:+971 585518437' className='text-primary'>
                    +971 585518437
                  </a>
                  /
                  <a href='tel:+91 9980028128' className='text-primary'>
                    +91 9980028128
                  </a>{" "}
                  One of our team will be in touch within 24 hours!
                </p>

                <br />
                {/* <Container>
                    <Row className="justify-content-center">
                      <Col lg={4}>
                        <Button
                          href="/sponsors"
                          className="btn my-2 text-center px-5"
                          color="primary"
                          size="lg"
                          outline
                        >
                          Contact Us
                        </Button>
                      </Col>
                    </Row>
                  </Container> */}
              </Col>
            </Row>
          </Container>
        </Container>
      </div>
    </>
  );
}

export default Speaker;

const Step1 = () => (
  <div>
    <h3 className='text-700 text-g mb-3'>
      Banks & FIs are enthusiastically researching & investing resources for:
    </h3>
    <ul className='text-white'>
      <li>
        <p className='text-400 text-white'>
          Biometric Innovation – Innovative Technology for Security in BFSI
          Industry
        </p>
      </li>
      <li>
        <p className='text-400 text-white'>Drive - Safe and Secure Banking</p>
      </li>
      <li>
        <p className='text-400 text-white'>
          Cyber security Policy – A perfect blends Strategy, Technology and
          Intelligence to confront security threats
        </p>
      </li>
      <li>
        <p className='text-400 text-white'>
          Cloud computing security & Data protection
        </p>
      </li>
      <li>
        <p className='text-400 text-white'>
          Improving - Risk and Fraud Assessment
        </p>
      </li>
      <li>
        <p className='text-400 text-white'>Meeting - cybersecurity challenge</p>
      </li>
      <li>
        <p className='text-400 text-white'>
          Critical Infrastructure – Safeguarding IT Infrastructure for future
          threats
        </p>
      </li>
      <li>
        <p className='text-400 text-white'>
          Remain agile by re-engineeringinnovation in payment security to lower
          the incidence of fraud
        </p>
      </li>
      <li>
        <p className='text-400 text-white'>
          Heavy Investment in emerging technologies such as, blockchain
          Technology, Artificial Intelligence, and Digital IDs.
        </p>
      </li>
    </ul>
  </div>
);

const Step2 = () => (
  <div>
    <h3 className='text-700 text-g mb-3'>
      In order for Banks and FIs to excel, they are actively seeking
      consultants/ technology vendors in:
    </h3>
    <ul className='text-white'>
      <li>
        <p className='text-400'>IT/ICT Service (Providers and Distributors)</p>
      </li>
      <li>
        <p className='text-400'>BFSI security</p>
      </li>
      <li>
        <p className='text-400'>Biometric Services</p>
      </li>
      <li>
        <p className='text-400'>Data and Analytics</p>
      </li>
      <li>
        <p className='text-400'>Cloud Migration & Open API Banking</p>
      </li>
      <li>
        <p className='text-400'>Cybersecurity & Fraud Solutions</p>
      </li>
      <li>
        <p className='text-400'>AML /KYC</p>
      </li>
      <li>
        <p className='text-400'>Digital Identity</p>
      </li>
      <li>
        <p className='text-400'>Digital Banking Platform</p>
      </li>
      <li>
        <p className='text-400'>AI, Blockchain & IOT Technology</p>
      </li>
      <li>
        <p className='text-400'>
          Customer Experience / User Experience and User DesignSolutions
        </p>
      </li>
    </ul>
  </div>
);

const Step3 = () => (
  <div className='text-white'>
    <h3 className='text-700 text-g mb-3'>
      In conclusion: Here’s how we can help you with tailor-made packages to
      gain higher exposure:
    </h3>
    <div className='mb-3'>
      <h5 className='text-700 text-g mt-0'>THOUGH LEADERSHIP</h5>
      <h5 className='text-400 mt-0'>
        Some of our sponsorship packages include speaking opportunities that are
        a powerful tool for your brand to showcase your expertise and build
        influence.
      </h5>
    </div>
    <div className='mb-3'>
      <h5 className='text-700 text-g mt-0'>BRANDING</h5>
      <h5 className='text-400 mt-0'>
        Position your brand on the main agenda, in emails, on our website, and
        throughout the event Digital marketing. Gain access to our extensive
        database. Over the last 1 year our team has worked with hundreds of tech
        companies to help maximize their brand exposure in an increasingly
        competitive marketplace.
      </h5>
    </div>
    <div className='mb-3'>
      <h5 className='text-700 text-g mt-0'>NETWORKING</h5>
      <h5 className='text-400 mt-0'>
        Host your own private breakfasts & Board room briefi ng, one-on-one
        meetings, cocktail parties and advance leads by demonstrating solutions
        face-to-face. With targeted invitations we have enabled you to cut time
        hunting down the right people so you can spend more time with the people
        that matter the most. Enjoy your networking throughout the day.
      </h5>
    </div>
    <div className='mb-3'>
      <h5 className='text-700 text-g mt-0'>FACE-TIME</h5>
      <h5 className='text-400 mt-0'>
        Build relationships onsite. Generate new leads and engage your sales
        team with 250+ digital, innovation, Security, and CX decision maker at
        your booth, sessions, roundtables and with dedicated networking app you
        can pre-schedule oneto-one meetings with the most promising industry
        leaders. We also have our very own Networking Host/Hostess on site whose
        sole purpose is to ensure that you and your selected prospects are the
        right match.
      </h5>
    </div>
    <div className='mb-3'>
      <h3 className='text-700 text-g mt-0'>
        The benefits of event sponsorship are endless if you put in the effort
        to forge connections.
      </h3>
    </div>
  </div>
);
// const Step1 = () => ();
// const Step1 = () => ();
