import React from "react";

import { Container, Row, Col } from "reactstrap";

function Speaker({ speakers }) {
  return (
    <>
      <div
        style={{
          backgroundColor: "#231f20",
          overflow: "hidden",
        }}
        className='section pt-0'
      >
        <Container
          fluid
          style={{
            backgroundImage:
              "url(" + require("assets/images/graphic23.png") + ")",
            backgroundAttachment: "fixed",
            backgroundRepeat: "no-repeat",
            backgroundSize: "cover",
          }}
        >
          <Container className='py-5'>
            <Row>
              <Col lg={11}>
                <h1 className='text-700 text-g'>
                  WE RUN A CLEAR SYNC BETWEEN THE PHYSICAL AND VIRTUAL
                  EXPERIENCE!
                </h1>
              </Col>
            </Row>
            <Row>
              <Col lg={11}>
                <p className='text-white text-500'>
                  As the UAE is perceived as safest destinations to host global
                  events and exhibitions, it also plays host to a rich and
                  diverse international community. Our international community
                  has helped us make the Finnovex Global Series what it is and
                  we understand that while Industry Peers would want to partake
                  in the event, they may not be able to join us due to travel
                  restrictions. And hence we are delighted to announce that
                  Finnosec Middle East 2022 will be a Hybrid Event.
                </p>

                <br />
              </Col>
            </Row>
          </Container>

          <Container className=' text-white'>
            <Row>
              <Col lg={9}>
                <div className='mb-3'>
                  <h5 className='text-700 mt-0 text-g'>Virtual Streaming</h5>
                  <h5 className='text-400 mt-0'>
                    Finnosec Middle East will be coming through with its first
                    fully-hybrid summit edition in the region bringing together
                    all Cybersecurity Experts of the Banking and Financial
                    Services ecosystem in-person/virtually and be the first of
                    its kind to offer for top-notch and valuable business
                    connections, power-packed panel discussions and insightful
                    presentations, straight to the GCC Region.
                  </h5>
                </div>
                <div className='mb-3'>
                  <h5 className='text-700 mt-0 text-g'>
                    Superior Strategic Networking
                  </h5>
                  <h5 className='text-400 mt-0'>
                    Be a part of an exciting 2-day Summit filled with an array
                    of personalized networking activities created to facilitate
                    the building of mutual relationships at the event venue and
                    via our own app as you exchange both your physical/virtual
                    business card. Regardless of the gap created between every
                    community as a result of the pandemic, we’re confident in
                    delivering a dynamic experience that fosters interaction
                    safely among all our attendees, attending in a hybrid
                    format.
                  </h5>
                </div>
                <div className='mb-3'>
                  <h5 className='text-700 mt-0 text-g'>Innovation Lounge</h5>
                  <h5 className='text-400 mt-0'>
                    Attendees can visit any of the physical and AI-Powered
                    virtual exhibition booths to access - engaging
                    product/solution demos about the best tools and
                    technologies; live chat between sponsors and delegates,
                    scheduled meetings and downloadable content.
                  </h5>
                </div>
                <div className='mb-3'>
                  <h5 className='text-700 mt-0 text-g'>
                    Engage with your Industry Peers in Real-Time
                  </h5>
                  <h5 className='text-400 mt-0'>
                    Chat and create new valuable bonds with top industry leaders
                    in the GCC Region, have fun and win virtual goodies! Get
                    involved with - Q&A sessions, Polls, use the intuitive chat
                    capability, take part in social media shout-outs.
                  </h5>
                </div>
              </Col>
            </Row>
            <Row>
              <Col lg={6}>
                <h1 className='text-700 text-g mb-3'>VIRTUAL BOOTH</h1>
                <img
                  src={require(`assets/images/backbase.jpg`)}
                  width='100%'
                  alt='icon'
                />
              </Col>
              <Col lg={6}>
                <h1 className='text-700 text-g mb-3'>PHYSICAL BOOTH</h1>
                <img
                  src={require(`assets/images/backbase-live.jpeg`)}
                  width='100%'
                  alt='icon'
                />
              </Col>
            </Row>
          </Container>
        </Container>
      </div>
    </>
  );
}

export default Speaker;
