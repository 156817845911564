import React from "react";
import ProfilePageHeader from "components/Headers/ProfilePageHeader";

import Navbar from "components/Navbar";
import Footer from "components/Footer";

import News1 from "components/news/news1";
import News2 from "components/news/news2";
import News3 from "components/news/news3";
import News4 from "components/news/news4";
import News5 from "components/news/news5";
import News6 from "components/news/news6";
import News7 from "components/news/news7";
import News8 from "components/news/news8";
import News9 from "components/news/news9";
import { Helmet } from "react-helmet";

import { live } from "./content";

import ScrollUpButton from "react-scroll-up-button";

function News({ match }) {
  React.useEffect(() => {
    window.scrollTo(0, 0);
  });
  const News = match.params.id;
  const n = live.find((x) => x.link === News);
  return (
    <div id="outer-container">
      <Helmet>
        <title>{n.description}</title>
        <link
          rel="canonical"
          href={`https://finnosec.com/finnoseclive/${n.link}`}
        />
        <meta name="description" content={n.description} />
        <meta property="og:site_name" content="FinnosecME" />
        <meta property="og:type" content="article" />
        <meta property="og:title" content={n.description} />
        <meta
          property="og:url"
          content={`https://finnosec.com/finnoseclive/${n.link}`}
        />
        {n.image ? (
          <meta
            property="og:image"
            itemprop="image"
            content={`https://finnosec.com/news/${n.image}`}
          />
        ) : (
          <meta
            property="og:image"
            itemprop="image"
            content={`https://finnosec.com/news/live.jpeg`}
          />
        )}
        <meta property="og:updated_time" content="1606928806" />
        <meta property="og:image:width" content="300" />
        <meta property="og:image:height" content="300" />
        <link
          rel="icon"
          type="image/png"
          sizes="100x100"
          href="https://finnosec.com/100.png"
        />
        <link
          rel="shortcut icon"
          type="image/png"
          sizes="144x144"
          href="https://finnosec.com/144.png"
        />

        <link
          rel="apple-touch-icon"
          sizes="57x57"
          href="https://finnosec.com/57.png"
        />
        <link
          rel="apple-touch-icon"
          sizes="60x60"
          href="https://finnosec.com/60.png"
        />
        <link
          rel="apple-touch-icon"
          sizes="72x72"
          href="https://finnosec.com/72.png"
        />
        <link
          rel="apple-touch-icon"
          sizes="76x76"
          href="https://finnosec.com/76.png"
        />
        <link
          rel="apple-touch-icon"
          sizes="114x114"
          href="https://finnosec.com/144.png"
        />
        <link
          rel="apple-touch-icon"
          sizes="120x120"
          href="https://finnosec.com/120.png"
        />
        <link
          rel="apple-touch-icon"
          sizes="144x144"
          href="https://finnosec.com/144.png"
        />
        <link
          rel="apple-touch-icon"
          sizes="152x152"
          href="https://finnosec.com/152.png"
        />
        <link
          rel="apple-touch-icon"
          sizes="180x180"
          href="https://finnosec.com/180.png"
        />
        <link
          rel="apple-touch-icon"
          sizes="192x192"
          href="https://finnosec.com/192.png"
        />
        <meta
          name="description"
          content="The Leading Summit Financial Security and Cyber Resilience"
        />
        <meta
          name="keywords"
          content="cyber security,breaches,dubai,uae,cyber resilience,cyber,event,hybrid,inperson,live"
        />
        <meta name="Author" content="Finnosec" />
      </Helmet>

      <Helmet></Helmet>
      <Navbar />
      <ScrollUpButton />
      <section id="" />
      <ProfilePageHeader title={n.description} />
      {n.id === 1 && <News1 news={n} />}
      {n.id === 2 && <News2 news={n} />}
      {n.id === 3 && <News3 news={n} />}
      {n.id === 4 && <News4 news={n} />}
      {n.id === 5 && <News5 news={n} />}
      {n.id === 6 && <News6 news={n} />}
      {n.id === 7 && <News7 news={n} />}
      {n.id === 8 && <News8 news={n} />}
      {n.id === 9 && <News9 news={n} />}

      <Footer />
    </div>
  );
}

export default News;
