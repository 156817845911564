import React from "react";
import { Container, Row, Col, Button } from "reactstrap";
import {
  FacebookShareButton,
  LinkedinShareButton,
  TwitterShareButton,
} from "react-share";

function news({ news }) {
  const shareUrl = "www.finnosec.com/finnoseclive/";
  console.log(news);
  return (
    <>
      <Container>
        <Row>
          <Col xs={12}>
            <div className="owner">
              <div className="avatar-big text-center">
                <img
                  alt=""
                  className="img-no-padding img-responsive img-rounded pt-2"
                  src={require(`assets/images/news/${news.image}`)}
                />
              </div>
            </div>
          </Col>
        </Row>

        <Row>
          <Col className={"ml-auto mr-auto my-2"} md="10">
            <h6>
              Source :{" "}
              <a href="https://cisomag.eccouncil.org/middle-east-cybersecurity-market-worth-29-9-bn-post-covid-19/">
                cisomag.eccouncil.org/middle-east-cybersecurity-market-worth-29-9-bn-post-covid-19/
              </a>
            </h6>
          </Col>
        </Row>
        <Row>
          <Col xs={1} className="d-none d-xl-block d-lg-block d-md-block"></Col>
          <FacebookShareButton
            url={`${shareUrl}${news.link}`}
            style={{ padding: 0, margin: 0, display: "inline !important" }}
            children={
              <Button
                color="neutral"
                outline
                style={{
                  padding: "0rem",
                  marginRight: "30px",
                  marginLeft: "15px",
                }}
              >
                <span style={{ color: "#3b5998", display: "inline" }}>
                  <i
                    style={{ fontSize: "50px" }}
                    className="fa fa-facebook-square float-left"
                  />
                </span>
              </Button>
            }
          />

          <LinkedinShareButton
            url={`${shareUrl}${news.link}`}
            style={{ padding: 0, margin: 0, display: "inline !important" }}
            children={
              <Button
                color="neutral"
                outline
                style={{
                  padding: "0rem",
                  marginRight: "30px",
                }}
              >
                <span style={{ color: "#0976b4" }}>
                  <i
                    style={{ fontSize: "50px" }}
                    className="fa fa-linkedin-square float-left"
                  />
                </span>
              </Button>
            }
          />
          <TwitterShareButton
            url={`${shareUrl}${news.link}`}
            style={{ padding: 0, margin: 0, display: "inline !important" }}
            children={
              <Button
                color="neutral"
                outline
                style={{
                  padding: "0rem",
                  marginRight: "30px",
                }}
              >
                <span style={{ color: "#55acee" }}>
                  <i
                    style={{ fontSize: "50px" }}
                    className="fa fa-twitter-square float-left"
                  />
                </span>
              </Button>
            }
          />
        </Row>

        <Row>
          <Col className={"ml-auto mr-auto text-justify"} md="10">
            <p>
              According to a new research, the Middle East cybersecurity
              pre-COVID-19 market size is estimated to grow from $16.1 billion
              in 2020 to $28.7 billion by 2025, at a Compound Annual Growth Rate
              (CAGR) of 12.2%, while the post-COVID-19 market size is projected
              to grow from $15.6 billion in 2020 to $29.9 billion by 2025, at a
              CAGR of 13.8%. The research report&nbsp;
              <a
                href="https://www.marketsandmarkets.com/Market-Reports/middle-east-cyber-security-market-121119697.html"
                target="_blank"
                rel="noopener noreferrer"
              >
                &ldquo;Middle East Cybersecurity Market
              </a>
              &rdquo; also revealed that the demand for small and medium
              enterprises (SMEs) in different industry verticals and countries
              is expected to grow during the forecast period.
            </p>
            <h3>Network Security is the Primary Segment</h3>
            <p>
              According to the research, the network security sector is
              estimated to have the largest market size post COVID-19. It is
              found that the evolving vulnerabilities in the networks of the
              energy and utilities, BFSI, IT and ITeS industry sectors triggered
              the growth of the&nbsp;
              <a
                href="https://staging-cisomagcom.kinsta.cloud/infoblox-to-host-cybersecurity-roadshow-2020-across-middle-east-and-africa/"
                target="_blank"
                rel="noopener noreferrer"
              >
                Middle East
              </a>
              &nbsp;cybersecurity market.
            </p>
            <p>
              &ldquo;With the growth of&nbsp;
              <a
                href="https://staging-cisomagcom.kinsta.cloud/?s=covid-19"
                target="_blank"
                rel="noopener noreferrer"
              >
                COVID-19
              </a>
              -related adaption in remote working practices, the network of
              enterprises in the Middle East has been jeopardized. Organizations
              are adopting measures to combat the situation and are
              collaborating with security vendors to manage the situation
              effectively,&rdquo; the report said.
            </p>
            <h3>Health Care to Grow at the Highest CAGR</h3>
            <p>
              The research also highlighted that the&nbsp;
              <a
                href="https://staging-cisomagcom.kinsta.cloud/83-of-healthcare-devices-at-security-risk-due-to-covid-19-outbreak/"
                target="_blank"
                rel="noopener noreferrer"
              >
                health care sector
              </a>
              &nbsp;is expected to contribute to the largest market share and to
              grow at the highest CAGR from 2019 to 2025, due to the increased
              security incidents during the pandemic. It will be followed by the
              government and defense sector, with second-highest CAGR from 2019
              to 2025.
            </p>
            <p>
              &ldquo;The demand for&nbsp;
              <a
                href="https://www.eccouncil.org/programs/"
                target="_blank"
                rel="noopener noreferrer"
              >
                cybersecurity solutions
              </a>
              &nbsp;is rising, as the adoption of these solutions provides
              advanced protection from cyber threats without affecting the
              operational efficiency,&rdquo; the report added.
            </p>
            <h3>Cyberattacks on Enterprises in the Middle East</h3>
            <p>
              Recently, security researchers from Cisco Talos&nbsp;
              <a
                href="https://staging-cisomagcom.kinsta.cloud/jhonerat-attacks-middle-east-countries/"
                target="_blank"
                rel="noopener noreferrer"
              >
                discovered
              </a>
              &nbsp;a new version of Remote Access Trojan named
              &ldquo;JhoneRAT&rdquo;, which targeted a set of enterprises in
              several Middle East countries including UAE, Saudi Arabia, and
              Iraq. The Trojan was developed using Python and attacked the
              victim&rsquo;s device via malicious Microsoft Office documents.
              The researchers advised users to avoid clicking file extensions
              from unknown sources.
            </p>
          </Col>
        </Row>
      </Container>
    </>
  );
}

export default news;
