import React from "react";
import { Container, Row, Col, Button } from "reactstrap";
import {
  FacebookShareButton,
  LinkedinShareButton,
  TwitterShareButton,
} from "react-share";

function news({ news }) {
  const shareUrl = "www.finnosec.com/finnoseclive/";
  console.log(news);
  return (
    <>
      <Container>
        <Row>
          <Col xs={12}>
            <div className="owner">
              <div className="avatar-big text-center">
                <img
                  alt=""
                  className="img-no-padding img-responsive img-rounded pt-2"
                  src={require(`assets/images/news/${news.image}`)}
                />
              </div>
            </div>
          </Col>
        </Row>

        <Row>
          <Col className={"ml-auto mr-auto my-2"} md="10">
            <h6>
              Source :{" "}
              <a href="https://www.securitymagazine.com/articles/92456-cyber-resilience-a-new-way-of-looking-at-cybersecurity">
                BNP Media Owner & Co-CEO, Tagg Henderson
              </a>
            </h6>
          </Col>
        </Row>
        <Row>
          <Col xs={1} className="d-none d-xl-block d-lg-block d-md-block"></Col>
          <FacebookShareButton
            url={`${shareUrl}${news.link}`}
            style={{ padding: 0, margin: 0, display: "inline !important" }}
            children={
              <Button
                color="neutral"
                outline
                style={{
                  padding: "0rem",
                  marginRight: "30px",
                  marginLeft: "15px",
                }}
              >
                <span style={{ color: "#3b5998", display: "inline" }}>
                  <i
                    style={{ fontSize: "50px" }}
                    className="fa fa-facebook-square float-left"
                  />
                </span>
              </Button>
            }
          />

          <LinkedinShareButton
            url={`${shareUrl}${news.link}`}
            style={{ padding: 0, margin: 0, display: "inline !important" }}
            children={
              <Button
                color="neutral"
                outline
                style={{
                  padding: "0rem",
                  marginRight: "30px",
                }}
              >
                <span style={{ color: "#0976b4" }}>
                  <i
                    style={{ fontSize: "50px" }}
                    className="fa fa-linkedin-square float-left"
                  />
                </span>
              </Button>
            }
          />
          <TwitterShareButton
            url={`${shareUrl}${news.link}`}
            style={{ padding: 0, margin: 0, display: "inline !important" }}
            children={
              <Button
                color="neutral"
                outline
                style={{
                  padding: "0rem",
                  marginRight: "30px",
                }}
              >
                <span style={{ color: "#55acee" }}>
                  <i
                    style={{ fontSize: "50px" }}
                    className="fa fa-twitter-square float-left"
                  />
                </span>
              </Button>
            }
          />
        </Row>

        <Row>
          <Col className={"ml-auto mr-auto text-justify"} md="10">
            <p>
              In the current climate of notorious data breaches and the
              constantly evolving cyberthreat landscape, banks and financial
              services companies are under pressure. They usually operate on
              vast volumes of valuable financial and customer data. That&rsquo;s
              why it&rsquo;s critical to learn everything there is to know about
              the current cyberthreats to keep it all safe from hackers.
            </p>
            <br />
            <p>
              Security professionals at financial services firms and banks will
              be looking at both old and brand-new cyber threats during the next
              few years.
            </p>
            <br />
            <p>
              <strong>In this article, we line up the most important</strong>
              &nbsp;<strong>banking cyber threats</strong>&nbsp;
              <strong>that will become prominent in 2020.</strong>&nbsp;Read on
              to find out what threats banks are going to be addressing with
              technology and what are the potential solutions they&rsquo;re
              going to explore this year.
            </p>
            <br />
            <br />
            <p>
              <img
                src="https://codete.com/blog/wp-content/uploads/2020/02/cyberthreats-in-banking-infographic.png"
                sizes="(max-width: 601px) 100vw, 601px"
                srcset="https://codete.com/blog/wp-content/uploads/2020/02/cyberthreats-in-banking-infographic.png 601w, https://codete.com/blog/wp-content/uploads/2020/02/cyberthreats-in-banking-infographic-199x300.png 199w"
                alt="cyberthreats in banking"
                width="601"
                height="907"
              />
            </p>
            <br />
            <br />
            <h2>Ransomware</h2>
            <p>
              In 2020, ransomware will remain one of the most significant
              challenges facing banks. After healthcare, financial services
              is&nbsp;
              <a href="https://www.greymatter.com/corporate/showcase/barracuda/ransomware/">
                the second most targeted industry
              </a>
              &nbsp;for&nbsp;<strong>ransomware attacks</strong>.
            </p>
            <br />
            <p>
              Successful attacks not only reveal the lack of appropriate
              endpoint protection but also serve as a starting point for many
              other issues. For example, a breach can cause monetary
              damage.&nbsp;
              <strong>
                But most importantly, banks that don&rsquo;t proactively protect
                against threats suffer from reputational risk among their
                customers.
              </strong>
            </p>
            <br />
            <p>
              <strong>
                This is only the tip of the iceberg. Other consequences of
                ransomware attacks are weekend staff morale and the need to pour
                plenty of IT resources into the response to the attack.
              </strong>
            </p>
            <br />
            <p>
              To adequately protect against ransomware attacks, financial
              institutions are going to concentrate on placing uniquely tailored
              layers throughout their networks to serve as obstacles that block
              malicious software.
            </p>
            <br />
            <h3>Phishing</h3>
            <p>
              The last few years have witnessed an increasing number of&nbsp;
              <strong>phishing scams</strong>. Some of them targeted
              specifically bank employees and attempted to obtain sensitive
              information such as usernames and passwords.
            </p>
            <br />
            <p>
              The idea here is inspiring the bank&rsquo;s employees to click on
              links or open attachments. They then redirect them to fake
              websites where they&rsquo;re encouraged to share their credentials
              and other personal information.
            </p>
            <br />
            <p>
              <strong>
                Once cybercriminals gain access to an employee&rsquo;s email
                account, they will be able to:
              </strong>
            </p>
            <br />
            <ul>
              <li>access critical company information,</li>
              <li>send emails on the bank&rsquo;s behalf,</li>
              <li>use the employees&rsquo; social media accounts,</li>
              <li>
                gain access to internal documents and customer financial
                information.
              </li>
            </ul>
            <p>
              <strong>
                As you can imagine, a breach like that could result in serious
                consequences &ndash; both financial and reputational. And
                that&rsquo;s valid not only for the institution itself but for
                individual employees.
              </strong>
            </p>
            <br />
            <p>
              What is the number one way to combat phishing attacks? Employee
              training. In 2020, more and more banks are going to invest their
              resources into teaching employees about how to spot such messages
              and handle them. That begins, of course, with not clicking on any
              links and alerting IT workers immediately.
            </p>
            <br />
            <h3>Third-party agreements</h3>
            <p>
              Many banks enter partnerships with third-party providers to reduce
              the costs associated with compliance. For example, rather than
              build their own solutions to meet compliance in-house, they source
              compliant resource solutions from cloud vendors and other
              partners.
            </p>
            <br />
            <p>
              However, by entering into this type of agreement, they may expose
              themselves to additional risks. After all, a bank&rsquo;s security
              posture is going to be only as strong as the posture of its
              third-party vendors. If a vendor faces a&nbsp;
              <strong>cyber threats</strong>, it might lead to the bank facing a
              vulnerability as well.
            </p>
            <br />
            <p>
              <strong>
                With this idea in mind, in 2020, banks are going to take a
                closer look at their third-party contracts &ndash; especially in
                the context of cybersecurity threats.
              </strong>
              &nbsp;They will be asking who is responsible for protecting data,
              what regulations do vendors follow to remain in compliance, and
              what could actually cause things to go wrong.
            </p>
            <br />
            <p>
              Banks that fail to consider this risk may suffer from serious
              financial and reputation losses. That&rsquo;s why choosing the
              cloud-based vendors carefully will become important too.
            </p>
            <br />
            <h3>DDoS attacks through the IoT</h3>
            <p>
              Another weak link in the financial services infrastructure is
              the&nbsp;<strong>Internet of Things (IoT)</strong>&nbsp;and its
              many implementations. Cloud-based technologies are not the only
              examples of how new threats arise with new solutions.
            </p>
            <br />
            <p>
              In&nbsp;<strong>2016</strong>, we witnessed the largest&nbsp;
              <strong>Distributed Denial of Service (DDoS) attack</strong>
              &nbsp;carried out through the Internet of Things. In this attack,
              unsecured IoT devices were hacked and, as a result, caused outages
              on sites like Amazon or Twitter.
            </p>
            <br />
            <p>
              <strong>
                Such threats aren&rsquo;t going away in 2020. In fact, they
                might be growing even more complex because of the proliferation
                of IoT devices.&nbsp;
              </strong>
              This trend is only going to increase in the near future. Consumers
              now use monitors, tablets, and a wide range of smart devices that
              all communicate with the internet.
            </p>
            <br />
            <p>
              Every financial institution needs to take this threat seriously.
              For example, if an IoT attack brings down your company website,
              your customers might not be able to log into their bank accounts
              or use a mobile banking app. And if consumers can&rsquo;t access
              their money until you gain control over the attack, your
              company&rsquo;s reputation will suffer.
            </p>
            <br />
            <p>
              <strong>
                In the US, senators introduced the Bipartisan Legislation that
                aims to decrease the cyber risk of the IoT
                implementations.&nbsp;
              </strong>
              The legislation ensures that device manufacturers meet minimum
              cybersecurity requirements &mdash; for example, enabling device
              patching to address security vulnerabilities or allowing users to
              change the default password. This legislation should reduce the
              overall risk of IoT devices, but it doesn&rsquo;t entirely
              eliminate that possibility. And banks will have to prepare for
              that in 2020.
            </p>
            <br />
            <h4>Multifactor authentication and biometric threats</h4>
            <p>
              You might believe that&nbsp;
              <strong>Multifactor authentication</strong>&nbsp;and biometric
              authentication provide an unsurmountable challenge to
              cybercriminals. Unfortunately, this is not true.
            </p>
            <br />
            <p>
              For example, hackers were known to exploit the vulnerabilities in
              the configuration of systems or flaws in remote banking systems
              and bypass one-time passcodes. Other hackers used social
              engineering for SIM swapping to deal with multifactor
              authentication.
            </p>
            <br />
            <p>
              <strong>What about biometrics?</strong>&nbsp;Many banks were
              hopeful about introducing biometric authentication as part of
              their two-factor authentication programs. However, it shows not to
              be so simple in practice. Several cases have demonstrated that
              biometric technology is still far from a perfect solution to&nbsp;
              <em>cyber threats</em>.
            </p>
            <br />
            <p>
              For example, banks have been seeing implementation issues such as
              Google Pixel smartphone that doesn&rsquo;t check whether the
              user&rsquo;s eyes are open during the unlocking process that uses
              facial characteristics. Another trend emerged in Latin America:
              visual capturing attacks. Cybercriminals would install CCTV
              cameras and use them to record the pins people used to unlock
              their phones. This technique is very simple but very effective
              &mdash; both for those who prefer PINs to fingerprints or facial
              reading. After all, when a device is greasy or dirty, the best way
              to unlock it is by using a PIN.
            </p>
            <br />
            <p>
              <strong>
                That&rsquo;s why in 2020, an increasing number of banks are
                going to introduce alternative methods of authentication.&nbsp;
              </strong>
              After all, no technology is perfect, and the recent cases have
              shown us that even seemingly bulletproof authentication methods
              can fall victim to cyber threats.
            </p>
            <br />
            <h2>Cyber Threats in banking: The future of cybersecurity</h2>
            <p>
              In 2020, more and more banks are going to invest in cybersecurity
              measures and develop solutions that address the most common
              vulnerabilities abilities. At the same time, they are going to
              provide their customers with new ways of accessing financial
              services and examine the potential vulnerabilities in these new
              products. As a result, cybersecurity is going to become one of the
              critical technology trends in the financial services sector.
            </p>
            <br />
            <p>
              Would you like to learn more about future technology trends in
              banking? Feel free to browse our company blog, where we share our
              expertise in the topic. Our teams have worked with financial
              services enterprises and fintech startups on a variety of
              solutions that included cybersecurity.
            </p>
            <br />
          </Col>
        </Row>
      </Container>
    </>
  );
}

export default news;
