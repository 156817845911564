import React from "react";
import { Container, Row, Col, Card, Button } from "reactstrap";

function Sponsor({ sponsors }) {
  let pageHeader = React.createRef();
  // const [overlay, setOverlay] = React.useState(false);
  return (
    <div
      style={{
        backgroundColor: "#231f20",
        // backgroundImage: "url(" + require("assets/images/graphic22.png") + ")",
        backgroundPosition: "top right",
        backgroundRepeat: "no-repeat",
        backgroundSize: "cover",
        backgroundAttachment: "fixed",

        zIndex: 5,
      }}
      className='section section-with-space'
      data-parallax={true}
      ref={pageHeader}
    >
      <Container>
        <Row>
          <Col lg={12}>
            <h1 className='text-center text-700 text-g pb-4 '>
              <b>2022 SPONSORS & PARTNERS</b>
            </h1>
          </Col>
        </Row>
        <Row>
          {Object.keys(sponsors).map((sponsor, index) => (
            <React.Fragment key={index}>
              <Col lg={12}>
                <h2
                  className='text-center text-white text-bold pb-4 text-uppercase'
                  style={{ fontSize: "35px" }}
                >
                  <b>{sponsors[sponsor].length > 1 ? `${sponsor}` : sponsor}</b>
                </h2>
                {/* <h2 className="text-center text-dark text-bold pb-4 text-uppercase"></h2> */}
              </Col>
              {sponsors[sponsor].map((data, i) => (
                <Col lg={data.grid || 3} className={"ml-auto mr-auto"} key={i}>
                  <a target='_blank' rel='noopener noreferrer' href={data.link}>
                    <Card style={{ borderRadius: 0 }}>
                      <img
                        src={require(`assets/images/sponsor/${data.image}`)}
                        alt={data.name}
                        width='100%'
                      />
                    </Card>
                  </a>
                </Col>
              ))}
            </React.Fragment>
          ))}
          <Col lg={12} className='text-center'>
            <Button
              href='/sponsors'
              className='btn my-2 text-center px-5'
              color='info'
              size='lg'
            >
              View All
            </Button>
          </Col>
        </Row>
      </Container>
    </div>
  );
}

export default Sponsor;
