import React from "react";

import { Container, Row, Col, Button } from "reactstrap";
import WhyAttend from "./WhyAttend";

function Speaker({ speakers }) {
  return (
    <>
      <div
        style={{
          backgroundColor: "#231f20",
          // backgroundImage:
          // 	'linear-gradient(to bottom,#000000, #011122,#01162c,#001b37,#001f41,#00234c, #002654, #01285b,#042b63, #042e69,#053070, #063376,#07357d)',
          // backgroundRepeat: 'no-repeat',
          // backgroundSize: 'cover',
          overflow: "hidden",
        }}
        className='section py-3'
      >
        <Container
          fluid
          style={{
            // backgroundImage:
            //   "url(" + require("assets/images/graphic23.png") + ")",
            backgroundAttachment: "fixed",
            backgroundRepeat: "no-repeat",
            backgroundSize: "cover",
          }}
        >
          <Container className='py-2'>
            <Row>
              <Col lg={11}>
                <h1 className='text-700 text-g'>
                  Don’t second-guess…the Future of Privacy is Now!
                </h1>
              </Col>
            </Row>
            <Row>
              <Col lg={7}>
                <p className='text-white text-500'>
                  With Cyber Security threats becoming increasingly
                  sophisticated, it is crucial to have the necessary frameworks
                  in place to protect the banking/financial institutions
                  infrastructure, processes and people from the security
                  challenges. That being said, the Finnovex Global Series
                  proudly presents the 3rd Annual Edition{" "}
                  <b>of FinnoSec Middle Eas</b>t, the Leading Summit on
                  Financial Security and Cyber Resilience,{" "}
                  <b>
                    to be held from the 8th September 2022 in a hybrid format
                    in Dubai UAE and Online
                  </b>
                  . Through FinnoSec we aim creating a platform where more than
                  350+ delegates from across the region and partners from across
                  the world will come together to discuss the prevalent data and
                  information technology security issues faced by banks and FIs,
                  enhance cybersecurity strategies and promote data protection
                  initiatives to safeguard the BFSI Industry against the
                  ever-evolving threats. Join the Summit with 40+ Pioneer
                  speakers and 350+ HNI delegates, to witness 8+ Networking
                  Hours, learn from 4+ Panel Discussions and 15+ Keynote.
                </p>
                <br />
              </Col>
              <Col lg={5}>
                <h1 className='text-700 text-g'>
                  Series World Class Hybrid Experience
                </h1>
                <div className='embed-responsive embed-responsive-16by9'>
                  <iframe
                    src='https://www.youtube.com/embed/nKU36f1cwmw'
                    frameBorder='0'
                    allow='accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture'
                    allowFullScreen
                    title='nKU36f1cwmw'
                  ></iframe>
                </div>
              </Col>
            </Row>
          </Container>
          <WhyAttend />
          <Container>
            <Row className='justify-content-center'>
              <Col lg={4} className='text-center'>
                <Button
                  href='/register'
                  className='btn my-2 text-center px-5'
                  color='primary'
                  size='lg'
                  outline
                >
                  Register Now
                </Button>
              </Col>
            </Row>
          </Container>
          <Container className='py-5'>
            <Row className='justify-content-center'>
              {images.map((id, i) => (
                <Col lg={4} xs={4} key={i}>
                  <img
                    alt='...'
                    className=' img-responsive my-3'
                    width='100%'
                    src={`https://drive.google.com/thumbnail?authuser=0&sz=w500&id=${id}`}
                  />
                </Col>
              ))}
            </Row>
          </Container>
        </Container>
      </div>
    </>
  );
}

export default Speaker;

const images = [
  "1vyWOYupm7qVx97IzZJRfgMSFWrIOuyEG",
  "1HdG9UZMGILGoCKYcdkH4DXRaWexcEGqE",
  "1E3iAWcqEbrqs6ULAtWxuqB2z27mGASg2",
  "1NCDxYZYa1pMiiYp8y7dSnKtBscOhiFbD",
  "1OHBMVDek9g_NgB6endspaJAsCRZ5IXS7",
  "1qKtagqbkOZGrOAXl9hauHRmxUO816gsQ",
  "1kpQmsk24PcwSjsssaQJJtb6sY_tJPQRo",
  "1hHMz1dh6nk1_kN--7rK_Qztm_WckdlJ0",
  "196Ca-MsuqpzXPQC73qgRH4I595llzZlD",
];
