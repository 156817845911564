import React from "react";
import { Container, Button, Row, Col } from "reactstrap";

function WhoShouldAttend() {
  return (
    <div
      style={{
        backgroundColor: "#fff",
      }}
      className="section"
      data-parallax={true}
    >
      <Container
        fluid
        style={{
          backgroundImage:
            "url(" + require("assets/images/graphic22.png") + ")",
          backgroundRepeat: "no-repeat",
          backgroundSize: "cover",
        }}
      >
        <Container>
          <Row>
            <Col lg={12} className="text-center">
              <h1 className="text-700 text-g">WHO ATTENDS?</h1>
              <h3 className=" text-500 text-center mb-5">
                Meet and Network with leaders and Security trailblazers from
                banking and FI industry involved in Financial Infrastructure
                Security. FinnoSec Attendees include:
              </h3>
            </Col>
          </Row>
          <Row className="justify-content-center">
            <Col lg={10}>
              <img
                src={require(`assets/images/whoattends.gif`)}
                width="100%"
                alt="icon"
              />
            </Col>
          </Row>
          <Row className="justify-content-center">
            <Button
              className="px-3 py-3"
              color="primary"
              size="lg"
              href="/register"
            >
              <h5 className="m-0 text-700" style={{ color: "#000" }}>
                Register Now
              </h5>
            </Button>
          </Row>
        </Container>
      </Container>
    </div>
  );
}
export default WhoShouldAttend;
