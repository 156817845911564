export const speakers = [
  {
    chairTitle: "INTERNATIONAL KEYNOTE SPEAKER",
    name: "Michael Meli",
    title: "Chief Information Security Officer, Managing Director",
    company: "Julius Baer, Switzerland",
    image: "Meli-Michael Reduced.jpg",
    companyImage: "juliusBaer.jpg",
  },
  {
    name: "Pillairkulam Parthasarath",
    title: "EVP -Group Chief Information Security Officer",
    company: "First Abu Dhabi Bank, UAE",
    image: "pillai.jpg",
    companyImage: "fab.png",
  },
  {
    name: "Illyas Kooliyankal",
    title: "Chief Information Security Officer",
    company: "Leading bank in Abu Dhabi, UAE",
    image: "ilyas.jpg",
    companyImage: undefined,
    link: "https://www.linkedin.com/in/illyas/",
  },
  {
    name: "Khalid Waheed CISSP, CISM, ISMS LA, COBIT",
    title: "Chief Information Security Officer",
    company: "Al Baraka Islamic Bank B.S.C., Bahrain",
    image: "khalid.jpg",
    companyImage: "baraka.png",
  },
  {
    name: "Abeer Khedr",
    title: "Head of Cyber Security",
    company: "National Bank of Egypt",
    image: "abeed.jpg",
    companyImage: "nbe.jpeg",
    link: "https://www.linkedin.com/in/abeerkhedr/",
  },
  {
    name: "Gökhan Yalçın",
    title: "Chief Information Security Officer ",
    company: "Yapı Kredi Bank, Turkey",
    image: "gokhan.jpg",
    companyImage: "yepi.png",
  },
  {
    name: "Amna Al Balushi",
    title:
      "Chief Information  Security Officer (CISO)- IT Governance & Security",
    company: "Bank Nizwa, Oman",
    image: "amna.jpg",
    companyImage: "nizwa.png",
  },
  {
    name: "Sultan Altukhaim",
    title: "Chief Information Security Officer (CISO) AGM",
    company: "Saudi Investment Bank, Saudi Arabia",
    image: "sultan.jpg",
    companyImage: "saudi.jpg",
    link: "https://www.linkedin.com/in/altukhaim/ ",
  },
  {
    name: "Somnath Sarkar",
    title: "Senior Vice President, Head of Information Security",
    company: "Mashreq Bank, UAE",
    image: "Somnath.png",
    companyImage: "mashreq.jpg",
  },
  {
    name: "Mohammed Ahmed Al Doseri",
    title: "Chief Information Security Officer",
    company: "Tasheel Finance, Saudi Arabia",
    image: "mohammed.jpg",
    companyImage: "tasheel.png",
    link: "https://www.linkedin.com/in/mohammed-aldoseri",
  },
  // {
  //   name: "Funda Öney",
  //   title: "Chief Information Officer",
  //   company: "HSBC, Turkey",
  //   image: "funda.jpg",
  //   companyImage: "hsbc.jpg",
  //   link: "https://www.linkedin.com/in/mohammed-aldoseri",
  // },

  {
    name: "Hussain Al Khalsan",
    title: "Chief Information Security Officer",
    company: "Zand, UAE",
    image: "khalsan.jpg",
    companyImage: "zand.jpg",
  },
  {
    name: "Babitha B P",
    title: "Chief Information Security Officer",
    company: "Catholic Syrian Bank, India",
    image: "babitha.jpg",
    companyImage: undefined,
  },
  {
    name: "Hariprasad Chede",
    title: "Chief Information Security Officer",
    company: " National Bank of Fujairah, UAE",
    image: "hari.png",
    companyImage: "nbf.png",
    link: "",
  },
  {
    name: "Sreedhar Suragouni",
    title: "Chief Information Officer",
    company: "Oman Insurance Company, UAE",
    image: "Sreedhar.jpg",
    companyImage: "oic.jpg",
    link: "https://www.linkedin.com/in/sreedhar-suragouni-058685b/?originalSubdomain=ae",
  },

  {
    name: "Kausar Nur Mukeri",
    title: "CISO-Head of Information Security",
    company: "Invest Bank P.S.C, UAE",
    image: "kausar.jpg",
    companyImage: "investbank.png",
  },

  {
    name: "Jumanah Kadri",
    title:
      "Blockchain Expert, Manager - Information Security Strategy & Performance",
    company: "Leading bank in Abu Dhabi",
    image: "jumanah.jpg",
    companyImage: undefined,
  },

  {
    name: "Ben Jenkins",
    title: "Senior Solutions Engineer",
    company: "ThreatLocker, United Kingdom",
    image: "ben Jenkins.jfif",
    companyImage: "threatlocker.jpg",
  },
];

export const pastspeakers = [
  // {
  //   keynote: true,
  //   name: "Bryan Seely",
  //   title: "",
  //   company:
  //     "World-renowned CyberSecurity expert, Ethical hacker, author and former U.S. Marine.",
  //   image: "seely.jpg",
  //   companyImage: undefined,
  //   link: "https://www.linkedin.com/in/bryanthemapsguy/",
  //   text:
  //     "Possibly the only Celebrated Ethical Hacker who wiretapped the Secret Service and the FBI",
  // },

  // {
  //   name: "Ken Coghill",
  //   title: "Director, Innovation & Technology Risk",
  //   company: "Supervision at Dubai Financial Services Authority",
  //   image: "ken.jpg",
  //   companyImage: "DFSA.jpg",
  //   link:
  //     "https://www.linkedin.com/in/ken-coghill-924851/?originalSubdomain=ae",
  // },
  // {
  //   name: "Bryan Stirewalt",
  //   title: "Chief Executive",
  //   company: "Dubai Financial Services Authority",
  //   image: "bryan.jpg",
  //   companyImage: "DFSA.jpg",
  //   link: "",
  // },
  // {
  //   name: "Majed Alshodari",
  //   title: `CISO of the Allied Cooperative Insurance Group (ACIG)
  //   Co-Founder of the first Saudi Association for Shared Services, Founder of MAITTC Consultancy
  //   `,
  //   company: "",
  //   image: "majed.jpg",
  //   companyImage: "aicg.jpg",
  //   link: "https://www.linkedin.com/in/malshodari/",
  // },
  {
    name: "Jelena Zelenovic Matone",
    title: "Chief Information Security Officer",
    company: "European Investment Bank, Luxembourg",
    image: "jelena.jpg",
    companyImage: "eib.png",
    link: "https://www.linkedin.com/in/jelenazelenovic/?originalSubdomain=lu",
  },

  {
    name: "Illyas Kooliyankal",
    title: "Chief Information Security Officer",
    company: "Abu Dhabi  Islamic bank",
    image: "ilyas.jpg",
    companyImage: "adib.jpg",
    link: "https://www.linkedin.com/in/illyas/",
  },
  {
    name: "Dr. Erdal Ozkaya",
    title: "Managing Director  & Chief information Security officer",
    company: "Standard Chartered UAE",
    image: "Erdal.jpg",
    companyImage: "SC.png",
    link: "https://www.linkedin.com/in/erdalozkaya/ ",
  },
  {
    name: "Surachai Chatchalermpun",
    title: "Chief Information Security Officer- SVP",
    company: "Krung Thai Bank, Thailand",
    image: "surachai.jpg",
    companyImage: "krung.jpg",
    link: "https://www.linkedin.com/in/surachai/",
  },

  // {
  //   name: "Amna Al Balushi",
  //   title: "Chief Information  Security Officer",
  //   company: "Bank Nizwa, Sultanate of Oman",
  //   image: "female.png",
  //   companyImage: "nizwa.jpg",
  //   link: "https://www.linkedin.com/in/amna-al-balushi-b8a88859/ ",
  // },
  {
    name: "Sridhar Iyer",
    title: "Executive Vice President & Head",
    company: "Mashreq Neo (Digital Bank)",
    image: "sridhar.jpg",
    companyImage: "mashreq.png",
    link: "https://www.linkedin.com/in/sridhar-iyer-1974874/",
  },
  {
    name: "Sultan Altukhaim",
    title: "Chief Information Security Officer (CISO) AGM",
    company: "Saudi Investment Bank",
    image: "sultan.jpg",
    companyImage: "sib.png",
    link: "https://www.linkedin.com/in/altukhaim/ ",
  },
  {
    name: "Hisham Mohamed",
    title: "Chief Information Security Officer",
    company: "Emirates NBD Egypt",
    image: "hisham.jpg",
    companyImage: "nbd.jpg",
    link: "https://www.linkedin.com/in/hishammohamedaly/?originalSubdomain=eg ",
  },
  {
    name: "Mohammed Ahmed Al Doseri",
    title: "Chief Information Security Officer",
    company: "Tasheel Finance, Saudi Arabia",
    image: "mohammed.jpg",
    companyImage: "tasheel.png",
    link: "https://www.linkedin.com/in/mohammed-aldoseri",
  },
  {
    name: "Abdullah Faisal Biary",
    title: "Chief Information Security Officer",
    company: "United Cooperative Assurance",
    image: "abdullah.jpeg",
    companyImage: "uci.png",
    link: "https://www.linkedin.com/in/eng-abdullah-b-32615898/?originalSubdomain=sa ",
  },
  {
    name: "Khalifa Al Shamsi",
    title: "VP - Head of Information Security",
    company: "Dubai Islamic Bank",
    image: "khalifa.png",
    companyImage: "dib.png",
    link: "https://www.linkedin.com/in/mohammed-alshaghdali-b50095104",
  },

  {
    name: "Nicola Garelli",
    title: "Chief Executive Officer",
    company: "Beema Insurance",
    image: "nicola.png",
    companyImage: "beema.jpg",
    link: "https://www.linkedin.com/in/dr-joseph-george-532258a/?originalSubdomain=ae ",
  },
  {
    name: "Dr.Joseph George",
    title: "CIO-Head of Information Systems & Technology",
    company: "National Bank of Fujairah",
    image: "joseph.jpg",
    companyImage: "fujairah.jpg",
    link: "https://www.linkedin.com/in/dr-joseph-george-532258a/?originalSubdomain=ae ",
  },
  {
    name: "Venkatesh Kallur",
    title: "Chief Risk Officer",
    company: "Commercial Bank International",
    image: "venkatesh.jpg",
    companyImage: "cb.png",
    link: "https://www.linkedin.com/in/venkateshkallur/",
  },
  {
    name: "Sreedhar Suragouni",
    title: "Chief Information Officer",
    company: "Oman Insurance Company, UAE",
    image: "sreedhar.jpg",
    companyImage: "oic.jpg",
    link: "https://www.linkedin.com/in/sreedhar-suragouni-058685b/?originalSubdomain=ae",
  },

  // {
  //   name: "Shlomi Avivi",
  //   title: "Chief Information Security Officer",
  //   company: "First Digital Bank, Israel",
  //   image: "speaker.png",
  //   companyImage: undefined,
  //   link:
  //     "https://www.linkedin.com/in/shlomi-avivi-8752811/?originalSubdomain=il",
  // },
  // {
  //   name: "Vijay Kumar T",
  //   title: "Chief Risk Officer",
  //   company: "Bahrain Development Bank",
  //   image: "Vijay.jpg",
  //   companyImage: "bdb.jpg",
  //   link: "https://www.linkedin.com/in/tvijay66/",
  // },
  {
    name: "Shaju Bhaskaran",
    title: "Chief Information Security Officer",
    company: "Ahli Bank Qatar",
    image: "shaju.jpg",
    companyImage: "ahli.png",
    link: "https://www.linkedin.com/in/shajubhaskaran/?locale=zh_CN",
  },

  {
    name: "Zsombor Brommer",
    title: "Chief Compliance Officer",
    company: "Al Hilal Bank",
    image: "Zsomer.jpg",
    companyImage: "alhilal.png",
    link: "https://www.linkedin.com/in/zsombor-brommer-799a506/",
  },

  {
    name: "Mohammed Alshaghdali",
    title: "Chief Information Security Officer",
    company: "Hala Saudi Arabia",
    image: "alshagh.jpeg",
    companyImage: "hala.jpg",
    link: "https://www.linkedin.com/in/mohammed-alshaghdali-b50095104",
  },
  {
    name: "Mohamed Mousa",
    title: "Chief Information Security and privacy officer (CISO)",
    company: "Ikea, KSA",
    image: "mohammedm.jpeg",
    companyImage: "ikea.jpg",
    link: "https://www.linkedin.com/in/mohamedsaadmousa/?originalSubdomain=sa",
  },
  {
    name: "Abdul Rahim Ahmad",
    title:
      "Chief Information Officer (CIO) and Chief Information Security Officer (CISO)",
    company: "Securities Exchange Commission of Pakistan (SECP)",
    image: "abdul.jpg",
    companyImage: "secp.jpg",
    link: "https://www.linkedin.com/in/mohamedsaadmousa/?originalSubdomain=sa",
  },
  {
    name: "Javed Jabbar",
    title: "Chief Information Security Officer Risk Management Group",
    company: "Samba Bank Limited",
    image: "Javed.jpg",
    companyImage: "samba.jpg",
    link: "https://www.linkedin.com/in/javedjabbar/?originalSubdomain=pk",
  },

  {
    name: "Ali Alsaegh",
    title: "Head of IT Security",
    company: "National Bank of Bahrain",
    image: "ali.jpg",
    companyImage: "nbb.png",
    link: "https://www.linkedin.com/in/ali-alsaegh-17b5009/",
  },
  {
    name: "Toby Wilmington",
    title: "Sales Engineer, EMEA",
    company: "Recorded Future",
    image: "toby.jpeg",
    companyImage: "recorded.png",
    link: "https://www.linkedin.com/in/tobywilmington/?originalSubdomain=uk",
  },
  {
    name: "Andrew Tsonchev",
    title: "Director of Technology",
    company: "Darktrace",
    image: "andrew.jpg",
    companyImage: "darktrace.png",
    link: "https://www.linkedin.com/in/atsonchev/?originalSubdomain=uk",
  },
  {
    name: "Roly Stride",
    title: "Regional Director, MENA",
    company: "Darktrace",
    image: "roly.jpg",
    companyImage: "darktrace.png",
    link: "https://www.linkedin.com/in/roly-stride-2b612751/?originalSubdomain=ae",
  },
  {
    name: "Dr. Konstantinos Tsanis",
    title: "Digital Transformation and FinTech Specialist",
    company: "International Finance Corporation",
    image: "Konstantinos.jpg",
    companyImage: "ifc.png",
    link: "https://www.linkedin.com/in/ktsanis/",
  },
  {
    name: "Mahmoud Yassin",
    title: "Head of IT Infrastructure & Security Operation ",
    company: "United Arab Bank",
    image: "mahmoud.jpg",
    companyImage: "uab.png",
    link: "https://www.linkedin.com/in/mayassin/",
  },
  {
    name: "Harri Sylvander",
    title: "Senior Consulting Manager, Mandiant",
    company: "A Fireeye Company",
    image: "harri.jpg",
    companyImage: "fireeye.png",
    link: "https://www.linkedin.com/in/harrisylvander/?originalSubdomain=ae",
  },
  {
    name: "Muhammed Muzammil Riyaz",
    title: "Sr. Vice President",
    company: "Centre Systems Group",
    image: "muzamil.jpg",
    companyImage: undefined,
    link: "",
  },
  {
    name: "Mr. A Karthik",
    title: "Chief Evangelist",
    company: "ManageEngine",
    image: "Karthik.jpg",
    companyImage: "ManageEngine.png",
    link: "",
  },
];

export const whosponsor = [
  {
    id: 1,
    title: "Thought Leadership",
    description: [
      "As a sponsor at FinnoSec, your company will gain second-to-none exposure to senior-level decisionmakers at the point in time that they are seeking solutions, information and systems for improving their Oranisations’ strategies. For a select few sponsors, you can build your reputation as a market leader through subject-specific presentations, workshops and focus days. This highly selective sponsorship allows your firm to establish tremendous capability and expertise in your specialty as well as highlight successful work completed with your clients.",
    ],
    location: "left",
    image: "1V78K5ha6gqvDjGfXMLVHzz1RWk-1Z9wY",
  },
  {
    id: 2,
    title: "PREMIUM BRANDING",
    description: [
      "We bring together buyers and suppliers for collaboration just like us 10x Meetings (Find out more - ask your show manager/director), networking and knowledge sharing. Branding is often a major initiative for our clients who are seeking to get the message out about their offerings. Build your company’s brand and visibility in front of senior decision-makers in order to get shortlisted. ",
      "As a sponsor, your company branding will appear alongside the global leaders associated with best practices in this field. Our dedicated marketing team will help you achieve your promotional aims in the months leading up to the conference. Exibex leverages multiple marketing channels including online, direct mail, email, press releases, media partnerships and social media like how we have built within a year brand like Finnovex Global Series in Middle East and Africa. Find out more about team working on FinnoSec who successfully executed Finnovex Global Series www.finnovex.com",
    ],
    location: "right",
    image: "1A20v1KotoAqslM1EBgwRQx0uYnXTxjM9",
  },
  {
    id: 3,
    title: "FEATURED NETWORKING EVENTS / FACE TIME:",
    description: [
      "Networking and information sharing are two major aspects of our conferences and Exibex builds in many opportunities for sponsors to benefit from meeting industry leaders. Focused and high-level, our summit will provide you with the perfect environment to initiate new business relationships, identify upcoming opportunities and achieve face-to-face contact that overcrowded tradeshows cannot deliver. The exhibition area is designed to be the heart of the event – a place to network and share strategies with key decision makers. Sponsorship opportunities range from exhibition stands to sponsored lunches, cocktail receptions, gala dinners and a host of other branding opportunities.",
      "Additionally, Exibex offers a selection of sponsorship opportunities that enables our clients to increase their opportunity to develop new relationships during our events, we have successfully Introduced more than 100+ Banks, FI meetings with our sponsors find out more on our YouTube Channel",
    ],
    location: "left",
    image: "1zuMuqVW9O2JXWjXGHSpay6T_kjU15OzJ",
  },
];

export const pastsponsors = [
  {
    name: "expo",
    image: "expo2020.jpg",
    type: "GOVERNMENT AFFLIATED PARTNERS",
    link: "",
    grid: 3,
  },
  {
    name: "difc",
    image: "difc.jpg",
    type: "GOVERNMENT AFFLIATED PARTNERS",
    link: "",
    grid: 3,
  },
  {
    name: "EDB Bahrain",
    image: "edb.jpg",
    link: "",
    type: "GOVERNMENT AFFLIATED PARTNERS",
    grid: 3,
  },
  {
    name: "oracle",
    image: "oracle.jpg",
    link: "",
    type: "SPONSORS AND PARTNERS",
    text: false,
    grid: 3,
  },
  {
    name: "daon",
    image: "daon.jpg",
    link: "",
    type: "SPONSORS AND PARTNERS",
    text: false,
    grid: 3,
  },
  {
    name: "onespan",
    image: "onespan.jpg",
    link: "",
    type: "SPONSORS AND PARTNERS",
    text: false,
    grid: 3,
  },
  {
    name: "tripwire",
    image: "tripwire.jpg",
    link: "",
    type: "SPONSORS AND PARTNERS",
    text: false,
    grid: 3,
  },
  {
    name: "rubrik",
    image: "rubrik.jpg",
    link: "",
    type: "SPONSORS AND PARTNERS",
    text: false,
    grid: 3,
  },
  {
    name: "tcs",
    image: "tcs.jpg",
    link: "",
    type: "SPONSORS AND PARTNERS",
    text: false,
    grid: 3,
  },
  {
    name: "pwc",
    image: "pwc.jpg",
    link: "",
    type: "SPONSORS AND PARTNERS",
    text: false,
    grid: 3,
  },
];
export const govsponsors = [];

export const sponsors = [
 
  {
    name: "expo",
    image: "expo2020.jpg",
    type: "Series Past Sponsors & Partners",
    link: "",
    grid: 3,
  },
  {
    name: "difc",
    image: "difc.jpg",
    type: "Series Past Sponsors & Partners",
    link: "",
    grid: 3,
  },
  {
    name: "EDB Bahrain",
    image: "edb.jpg",
    link: "",
    type: "Series Past Sponsors & Partners",
    grid: 3,
  },
  {
    name: "oracle",
    image: "oracle.jpg",
    link: "",
    type: "Series Past Sponsors & Partners",
    text: false,
    grid: 3,
  },
  {
    name: "daon",
    image: "daon.jpg",
    link: "",
    type: "Series Past Sponsors & Partners",
    text: false,
    grid: 3,
  },
  {
    name: "onespan",
    image: "onespan.jpg",
    link: "",
    type: "Series Past Sponsors & Partners",
    text: false,
    grid: 3,
  },
  {
    name: "tripwire",
    image: "tripwire.jpg",
    link: "",
    type: "Series Past Sponsors & Partners",
    text: false,
    grid: 3,
  },
  {
    name: "rubrik",
    image: "rubrik.jpg",
    link: "",
    type: "Series Past Sponsors & Partners",
    text: false,
    grid: 3,
  },
  {
    name: "tcs",
    image: "tcs.jpg",
    link: "",
    type: "Series Past Sponsors & Partners",
    text: false,
    grid: 3,
  },
  {
    name: "pwc",
    image: "pwc.jpg",
    link: "",
    type: "Series Past Sponsors & Partners",
    text: false,
    grid: 3,
  },

  {
    name: "BeinCrypto",
    image: "beincrypto.jpeg",
    link: "https://www.beincrypto.com",
    type: "MEDIA PARTNERS",
    text: false,
    grid: 3,
  },
  {
    name: "The Power 50",
    image: "fintechpower.jpeg",
    link: "https://www.thepower50.com",
    type: "MEDIA PARTNERS",
    text: false,
    grid: 3,
  },
  {
    name: "Fintechna",
    image: "fintechna.jpeg",
    link: "https://www.fintechna.com",
    type: "MEDIA PARTNERS",
    text: false,
    grid: 3,
  },
  {
    name: "Cryptonewz",
    image: "cryptonewz.jpeg",
    link: "https://www.cryptonewsz.com",
    type: "MEDIA PARTNERS",
    text: false,
    grid: 3,
  },

  {
    name: "International Business Magazine",
    image: "ibm.jpeg",
    link: "https://www.intlbm.com",
    type: "MEDIA PARTNERS",
    text: false,
    grid: 3,
  },
  {
    name: "Oxford Business Group",
    image: "oxford.jpeg",
    link: "https://www.obg.com",
    type: "MEDIA PARTNERS",
    text: false,
    grid: 3,
  },
  {
    name: "CAIA",
    image: "caia.jpeg",
    link: "https://www.caia.com",
    type: "MEDIA PARTNERS",
    text: false,
    grid: 3,
  },
  {
    name: "The Fintech Times",
    image: "fintechtimes.jpeg",
    link: "https://www.thefintechtimes.com",
    type: "MEDIA PARTNERS",
    text: false,
    grid: 3,
  },
  {
    name: "News Affinity",
    image: "NewsAffinity.jpeg",
    link: "https://www.newsaffinity.com",
    type: "MEDIA PARTNERS",
    text: false,
    grid: 3,
  },
  {
    name: "Cointelegraph",
    image: "ct.jpeg",
    link: "https://www.cointelegraph.com",
    type: "MEDIA PARTNERS",
    text: false,
    grid: 3,
  },
  {
    name: "Silicon India",
    image: "silicon.jpeg",
    link: "https://www.siliconindia.com",
    type: "MEDIA PARTNERS",
    text: false,
    grid: 3,
  },
  {
    name: "Financial IT",
    image: "financialit.jpeg",
    link: "https://www.financialit.com",
    type: "MEDIA PARTNERS",
    text: false,
    grid: 3,
  },
  {
    name: "Findbiometrics",
    image: "find.jpeg",
    link: "https://www.findbiometrics.com",
    type: "MEDIA PARTNERS",
    text: false,
    grid: 3,
  },
  {
    name: "Mobile ID World",
    image: "mobile.jpeg",
    link: "https://www.mobileidworld.com",
    type: "MEDIA PARTNERS",
    text: false,
    grid: 3,
  },
  {
    name: "Coinmercury",
    image: "coinmercury.jpeg",
    link: "https://www.coinmercury.com",
    type: "MEDIA PARTNERS",
    text: false,
    grid: 3,
  },
  {
    name: "Industry Events",
    image: "ie.jpg",
    link: "http://www.industryevents.com/",
    type: "MEDIA PARTNERS",
    text: false,
    grid: 3,
  },
];

export const newsponsors = [
  {
    image: "threatLocker.jpg",
    type: "Platinum Sponsor",
    grid: 4,
    link: "https://www.backbase.com/",
  },
  {
    name: "ManageEngine",
    image: "ManageEngine.png",
    type: "Gold Sponsor",
    link: "",
    grid: 4,
  },
// {
//     name: "Accura Scan",
//     image: "accurascan.jpg",
//     type: "ID Verification & eKYC Partner",
//     text: ``,
//     link: "https://accurascan.com/",
//     grid: 4,
//   },
  {
    name: "Recorded Future",
    image: "recorded.png",
    type: "Past Sponsors & Partners",
    link: "https://www.recordedfuture.com/",
    grid: 4,
  },
  {
    name: "Darktrace",
    image: "darktrace.png",
    type: "Past Sponsors & Partners",
    link: "https://www.darktrace.com/en/",
    grid: 4,
  },
  {
    name: "Fireeye",
    image: "fireeye.png",
    type: "Past Sponsors & Partners",
    link: "https://www.fireeye.com/",
    grid: 4,
  },


  {
    name: "CSA United Arabs Chapter",
    image: "csa.png",
    type: "Past Sponsors & Partners",
    link: "",
    grid: 4,
  },
];
const groupBy = (key) => (array) =>
  array.reduce((objectsByKeyValue, obj) => {
    const value = obj[key];
    objectsByKeyValue[value] = (objectsByKeyValue[value] || []).concat(obj);
    return objectsByKeyValue;
  }, {});

const groupByType = groupBy("type");
export const sponsorsPage = groupByType(sponsors);
export const pastSponsorPage = groupByType(pastsponsors);
export const newSponsorPage = groupByType(newsponsors);

export const live = [
  {
    id: 9,
    link: "Speaker-Interview-Venkatesh-Kallur.",
    description: "Speaker Interview- Venkatesh Kallur.",
    content: "Chief Risk Officer, Commercial Bank International, UAE ",
    image: "9.jpg",
  },
  {
    id: 8,
    link: "Middle East Cybersecurity Market Worth $29.9 Bn Post COVID-19",
    description:
      "Middle East Cybersecurity Market Worth $29.9 Bn Post COVID-19",
    content: "Middle East Cybersecurity Market Worth $29.9 Bn Post COVID-19",
    image: "8.jpeg",
  },
  {
    id: 7,
    link: "Top 10 things that will drive banks in 2020",
    description: "Top 10 things that will drive banks in 2020",
    content:
      "The last year of the decade is ending with dramatic changes in the banking sector. ",
    image: undefined,
  },
  {
    id: 6,
    link: "5 Critical Cyber Threats in Banking for 2020",
    description: "5 Critical Cyber Threats in Banking for 2020",
    content:
      "n the current climate of notorious data breaches and the constantly evolving cyberthreat landscape, banks and financial services companies are under pressure. ",
    image: "6.jpeg",
  },
  {
    id: 5,
    link: "4 Steps to Help You Plan a Cyber Resilience Roadmap",
    description: "4 Steps to Help You Plan a Cyber Resilience Roadmap",
    content:
      "The need for real-time visibility of an organization’s security posture is becoming increasingly evident. ",
    image: undefined,
  },
  {
    id: 4,
    link: "Cyber-Resilience-A-New-Way-of-Looking-at-Cybersecurity",
    description: "Cyber Resilience: A New Way of Looking at Cybersecurity",
    content: "4 Steps to Help You Plan a Cyber Resilience Roadmap",
    image: undefined,
  },
  {
    id: 1,
    link: "Is-banking-security-in-Africa-holding-fintech-back",
    description: "Is banking security in Africa holding fintech back?",
    content:
      "New figures released by the SABRIC, revealed a huge spike in credit card fraud, up an eyewatering 18% year-on-year",
    image: "1.jpeg",
  },
  {
    id: 2,
    link: "Will-Biometric-Technology-Take-Over-Banking-Security",
    description: "Will Biometric Technology Take Over Banking Security?",
    content:
      "If you haven’t heard the news, more banks are adopting biometric technology for customer authentication to increase security",
    image: "2.jpeg",
  },
  {
    id: 3,
    link: "Africa-is-leaving-itself-dangerously-exposed-to-cyber-attacks",
    description:
      "Africa is leaving itself dangerously exposed to cyber attacks",
    content:
      "In their rush to embrace the digital future, African entities are leaving themselves dangerously exposed to cyber attacks",
    image: "3.jpeg",
  },
];
