import React from "react";
import { Container, Row, Col, Button } from "reactstrap";

function Awards() {
  let pageHeader = React.createRef();
  const size =
    document.body.clientWidth >= 1024 ||
    document.documentElement.clientWidth >= 1024
      ? true
      : false;
  return (
    <div
      style={{
        backgroundImage:
          "url(" +
          require(`assets/images/${
            size ? "awardsbackground.jpg" : "awardsbackgroundmobile.jpg"
          }`) +
          ")",
        backgroundRepeat: "no-repeat",
        backgroundSize: "100%",
      }}
      className="section py-3"
      data-parallax={true}
      ref={pageHeader}
    >
      <Container>
        <Row>
          <Col lg={4}>
            <img
              src={require(`assets/images/logo/awardslogo.png`)}
              width="100%"
              alt="awards"
            />
          </Col>
          <Col lg={8} className="align-self-center px-3">
            <h2 className="text-700 text-g mb-0">
              THE REGION’S FIRST AND ONLY FINANCIAL INFRASTURTURE SECURITY
              EXCELLENCE AWARDS
            </h2>
            <p className="text-white text-400 mt-1">
              In our efforts to continue pushing the envelope of Financial
              Security Excellence and to honour the leaders who have excelled
              and set a standard in this domain, we will be hosting the Middle
              East regions’ First & only award ceremony for Financial Security
              leaders.
            </p>
            <Button
              className="px-3 py-3 mt-3 text-center"
              color="primary"
              size="lg"
              outline
              href="/awards"
            >
              <h3 className="m-0 text-700" style={{ color: "#fff" }}>
                <b>Nominate Now</b>
              </h3>
            </Button>
          </Col>
        </Row>
      </Container>
    </div>
  );
}

export default Awards;
