import React from "react";
import {
  Button,
  Container,
  Row,
  Col,
  Input,
  Form,
  Card,
  Alert,
  Spinner,
} from "reactstrap";

function Register() {
  const [details, setDetails] = React.useState({
    subject: "FinnoSec Award Nomination",
    source: "FINNOSECME",
  });
  const [loading, setLoading] = React.useState(false);
  const [error, setError] = React.useState(false);
  const [success, setSuccess] = React.useState(false);
  document.documentElement.classList.remove("nav-open");
  React.useEffect(() => {
    document.body.classList.add("register-page");
    return function cleanup() {
      document.body.classList.remove("register-page");
    };
  });

  const onChangeDetails = (e) => {
    setDetails({ ...details, [e.target.name]: e.target.value });
    setError(false);
    setLoading(false);
    setSuccess(false);
  };

  const onSubmitForm = (e) => {
    e.preventDefault();
    setLoading(true);
    setError(false);
    setSuccess(false);
    const reg = /[a-zA-Z0-9]*@[gmail|yahoo|hotmail]+\.[com|co.in]+/;
    if (reg.test(details.email.toLowerCase())) {
      setLoading(false);
      setError("Please enter your business email only");
      return null;
    }
    if (reg.test(details.nomineeEmail.toLowerCase())) {
      setLoading(false);
      setError("Please enter Nominee business email only");
      return null;
    }
    fetch(
      "https://us-central1-finnosec.cloudfunctions.net/api/emailawards",
      {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        body: JSON.stringify(details),
      }
    )
      .then((res) => {
        console.log(res);
        if (res.ok) return res;
        else throw new Error(res);
      })
      .then(() => {
        setLoading(false);
        setSuccess(
          "Thank you for your request. We'll get back to you shortly."
        );
      })
      .catch((err) => {
        setLoading(false);
        setError("Something went wrong please try again");
      });
  };
  return (
    <div>
      <Container>
        <Row>
          <Col className="mx-auto" lg={8}>
            <Card
              className="card-register mx-auto"
              style={{
                backgroundImage:
                  "linear-gradient(to bottom, #011122, #01162c, #001b37, #001f41, #00234c, #002654, #01285b, #042b63, #042e69, #053070, #063376, #07357d)",
                maxWidth: "1000px",
              }}
            >
              <Container className="my-auto">
                <Form onSubmit={onSubmitForm} className="register-form">
                  <Row>
                    <Col lg={12}>
                      <h4 className="text-700 p-3 text-center">AWARDS</h4>
                      <p className="text-center">
                        You can nominate your organisation or yourself for any
                        of the categories above or please send an email to
                        info@exibex.com
                      </p>
                    </Col>
                    <Col lg={6}>
                      <label>Award Category*</label>
                      <Input
                        type="select"
                        name="category"
                        id=""
                        onChange={onChangeDetails}
                        required
                      >
                        <option value="">Select</option>
                        <option>Excellence in Data Analytics</option>
                        <option>Excellence in Response</option>
                        <option>Excellence in Biometrics</option>
                        <option>Excellence in End-Point Security</option>
                        <option>Excellence in Cards and Payments </option>
                        <option>Excellence in Authentication</option>
                        <option>Excellence in Cyber Security</option>
                        <option>Excellence in Access Management</option>
                        <option>Excellence in Cloud Security</option>
                        <option>
                          Excellence in Artificial Intelligence Security
                        </option>
                        <option>Excellence in Encryption</option>
                        <option>Excellence in IoT Security</option>
                        <option>Excellence in Threat Detection</option>
                        <option>Excellence in AML Strategy</option>
                        <option>Excellence in Fraud Prevention</option>
                        <option>
                          Excellence in Critical Infrastructure Security
                        </option>
                        <option>FinnoSec Service Provider of the Year</option>
                        <option>Emerging Security Solution of the Year</option>
                        <option>CISO of the Year Middle East</option>
                        <option>CIO of the Year Middle East</option>
                        <option>CTO of the Year Middle East</option>
                        <option>FinnoSec Influencer of the Year</option>
                      </Input>
                    </Col>
                    <Col lg={6}>
                      <label>Reason for Nomination*</label>
                      <Input
                        placeholder="Reason"
                        type="text"
                        name="reason"
                        onChange={onChangeDetails}
                        required
                      />
                    </Col>
                    <Col lg={12}>
                      <h4 className="text-700 pb-1 pt-5 text-center">
                        NOMINEE’S INFORMATION
                      </h4>
                    </Col>
                    <Col lg={6}>
                      <label>Nominee Name*</label>
                      <Input
                        placeholder="Name"
                        type="text"
                        name="nomineeName"
                        onChange={onChangeDetails}
                        required
                      />
                    </Col>
                    <Col lg={6}>
                      <label>Nominee Phone (with country code)*</label>
                      <Input
                        placeholder="Phone"
                        type="text"
                        name="nomineePhone"
                        onChange={onChangeDetails}
                        required
                      />
                    </Col>

                    <Col lg={12}>
                      <label>
                        Nominee's E-mail Address (official email only) *
                      </label>
                      <Input
                        placeholder="Email"
                        type="text"
                        name="nomineeEmail"
                        onChange={onChangeDetails}
                        required
                      />
                    </Col>

                    <Col lg={12}>
                      <h4 className="text-700 pb-1 pt-5 text-center">
                        YOUR INFORMATION
                      </h4>
                    </Col>
                    <Col lg={6}>
                      <label>Name*</label>
                      <Input
                        placeholder="Name"
                        type="text"
                        name="name"
                        onChange={onChangeDetails}
                        required
                      />
                    </Col>

                    <Col lg={6}>
                      <label>Phone (with country code)*</label>
                      <Input
                        placeholder="Phone"
                        type="text"
                        name="phone"
                        onChange={onChangeDetails}
                        required
                      />
                    </Col>

                    <Col lg={12}>
                      <label>E-mail Address (official email only)*</label>
                      <Input
                        placeholder="Email"
                        type="text"
                        name="email"
                        onChange={onChangeDetails}
                        required
                      />
                    </Col>
                  </Row>

                  <Button
                    block
                    className="btn text-white text-700"
                    color="primary"
                    size="lg"
                    type="submit"
                    disabled={loading}
                  >
                    Submit
                    {loading ? (
                      <span>
                        {" "}
                        <Spinner color="warning" size="sm" />
                      </span>
                    ) : (
                      ""
                    )}
                  </Button>
                </Form>
              </Container>
              {error ? (
                <Alert
                  color="danger"
                  isOpen={true}
                  fade={true}
                  className="text-center"
                  style={{ marginTop: "1rem" }}
                >
                  {error}
                </Alert>
              ) : (
                ""
              )}
              {success ? (
                <Alert
                  color="success"
                  isOpen={true}
                  fade={true}
                  className="text-center"
                  style={{ marginTop: "1rem" }}
                >
                  {success}
                </Alert>
              ) : (
                ""
              )}
            </Card>
          </Col>
        </Row>
      </Container>
    </div>
  );
}

export default Register;
