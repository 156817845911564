import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter, Route, Switch } from "react-router-dom";

import "assets/css/bootstrap.min.css";
// import 'assets/scss/paper-kit.scss';
import "assets/css/paper-kit.min.css";
import "assets/demo/demo.css";
import Home from "views/home.js";
import Speakers from "views/speakers.js";
import PastSpeakers from "views/pastspeakers";
import Sponsors from "views/sponsors.js";
import SponsorsAll from "views/sponsorsall.js";
import About from "views/about.js";
import Delegates from "views/delegates.js";
import Award from "views/award.js";
import Register from "views/register.js";
import NewsAll from "views/newsall.js";
import News from "views/news.js";
import HybridDetail from "views/hybrid.js";
import Safety from "views/safety.js";

ReactDOM.render(
  <BrowserRouter>
    <Switch>
      <Route path='/' exact render={(props) => <Home {...props} />} />
      <Route
        path='/speakers'
        exact
        render={(props) => <Speakers {...props} />}
      />
      <Route
        path='/pastspeakers'
        exact
        render={(props) => <PastSpeakers {...props} />}
      />
      <Route
        path='/sponsors'
        exact
        render={(props) => <Sponsors {...props} />}
      />
      <Route
        path='/sponsors-partners'
        exact
        render={(props) => <SponsorsAll {...props} />}
      />
      <Route path='/safety' exact render={(props) => <Safety {...props} />} />
      <Route path='/about' exact render={(props) => <About {...props} />} />
      <Route
        path='/delegates'
        exact
        render={(props) => <Delegates {...props} />}
      />
      <Route path='/awards' exact render={(props) => <Award {...props} />} />
      <Route
        path='/finnoseclive'
        exact
        render={(props) => <NewsAll {...props} />}
      />
      <Route
        path='/finnoseclive/:id'
        exact
        render={(props) => <News {...props} />}
      />
      <Route
        path='/delegates/:id'
        exact
        render={(props) => <Delegates {...props} />}
      />
      <Route
        path='/register'
        exact
        render={(props) => (
          <Register {...props} title='Register' type='REGISTER' />
        )}
      />
      <Route
        path='/request-brochure'
        exact
        render={(props) => (
          <Register title='Request Brochure' {...props} type='BROCHURE' />
        )}
      />
      <Route
        path='/hybrid-event'
        exact
        render={(props) => <HybridDetail {...props} />}
      />
    </Switch>
  </BrowserRouter>,
  document.getElementById("root")
);
