import React from "react";
import { Row, Container, Col, Button } from "reactstrap";

function DemoFooter() {
  return (
    <footer className='footer footer-black footer-white text-center'>
      <Container fluid>
        <Row>
          <Col lg={4} className='px-5 pt-5 pb-3'>
            <Container fluid>
              <Row>
                <Col lg={12}>
                  <a href='/'>
                    <img
                      src={require("assets/images/logo/footerlogo.png")}
                      alt='exibex'
                      width='100%'
                      className='mx-auto'
                    />
                  </a>
                </Col>
              </Row>
              <Row>
                <Col lg={12} className=''>
                  <a href='http://finnovex.com'>
                    <img
                      src={require(`assets/images/logo/finnovexdarkfooter.png`)}
                      className='pt-3 pl-4 mx-auto'
                      width='50%'
                      // style={{ maxWidth: "150px" }}
                      alt='logo'
                    />
                  </a>
                </Col>
              </Row>
            </Container>
          </Col>
          <Col
            lg={4}
            className='text-center mx-auto px-5 py-3'
            style={{
              borderLeft: "1px dotted #fdc300",
              borderRight: "1px dotted #fdc300",
            }}
          >
            <h3 className='text-700'>CONTACT US</h3>

            <a href='/' className='mx-auto'>
              <img
                src={require("assets/images/logo/exibex.gif")}
                alt='vibe'
                width='50%'
              />
            </a>

            <h6 className='mx-auto p-0 mt-0' style={{ textTransform: "none" }}>
              Exibex FZ LLE
              <br />
              Office 1309,13th Floor, Creative Tower, Fujairah
              <br />
              United Arab Emirates
              <br />
              +44 20 3290 7867 | +91 9980028128
              <br />
              Email: info@exibex.com
            </h6>
          </Col>
          <Col lg={4} className='px-5 py-3 align-self-center'>
            <h5 className='text-700'>
              Stay Updated On The Latest Trends On Financial Security Follow Us
            </h5>

            <Button
              className='btn-neutral btn-just-icon '
              style={{ backgroundColor: "#fdc300", color: "#000" }}
              href='https://twitter.com/finnosec?s=09'
            >
              <i className='fa fa-twitter' />
            </Button>
            <Button
              className='btn-neutral btn-just-icon '
              style={{ backgroundColor: "#fdc300", color: "#000" }}
              href='https://www.linkedin.com/company/finnosec'
            >
              <i className='fa fa-linkedin' />
            </Button>
            <Button
              className='btn-neutral btn-just-icon  '
              style={{ backgroundColor: "#fdc300", color: "#000" }}
              href='https://instagram.com/finnosec?igshid=1lz65cm3dssul'
            >
              <i className='fa fa-instagram' />
            </Button>
            <Button
              className='btn-neutral btn-just-icon'
              style={{ backgroundColor: "#fdc300", color: "#000" }}
              href='https://wa.me/971585518437?text=Hi'
            >
              <i className='fa fa-whatsapp' />
            </Button>
          </Col>
        </Row>

        <Row>
          <Col
            xs={12}
            className={"text-center pt-1 m-auto"}
            style={{ borderTop: "1px dotted #fdc300" }}
          >
            <h6 style={{ textTransform: "none" }}>© 2022 Exibex FZ LLE. All Rights Reserved.</h6>
          </Col>
        </Row>
      </Container>
    </footer>
  );
}

export default DemoFooter;
