import React from "react";
import { Link } from "react-router-dom";

function ProfilePageHeader({ title }) {
  const size =
    document.body.clientWidth >= 1024 ||
    document.documentElement.clientWidth >= 1024
      ? true
      : false;
  let pageHeader = React.createRef();
  return (
    <>
      <div
        style={{
          backgroundColor: "#fdc300",
          paddingTop: size ? "6rem" : "5rem",
        }}
        className="section pb-2"
        data-parallax={true}
        ref={pageHeader}
      >
        {title && (
          <>
            <h1 className={"text-center text-700 mt-0"} style={{ zIndex: 10 }}>
              <b>{title}</b>
            </h1>
            <h6 className={"text-center text-white text-700 mt-0"}>
              <Link to="/" className={"text-center text-info text-700 mt-0"}>
                HOME
              </Link>{" "}
              / {title}
            </h6>
          </>
        )}
      </div>
    </>
  );
}

export default ProfilePageHeader;
