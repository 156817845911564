// reactstrap components
import {
  Container,
  Col,
  Row,
  Button,
  Modal,
  ModalBody,
  ModalHeader,
} from "reactstrap";

import React, { useState, useEffect, useRef } from "react";
import { size } from "../common";
import Matrix from "components/Matrix";

function LandingPageHeader() {
  // const size =
  //   document.body.clientWidth >= 1024 ||
  //   document.documentElement.clientWidth >= 1024
  //     ? true
  //     : false;
  // const navType = document.body.clientWidth >= 1024 || document.documentElement.clientWidth > 1024 ? true : false;
  // const [vantaEffect, setVantaEffect] = useState(0);
  const myRef = useRef(null);

  const [modal, setModal] = useState(true);

  const toggle = () => setModal(!modal);
  const [modalRegister, setModalRegister] = useState(false);
  const toggleRegister = () => setModalRegister(!modalRegister);
  // useEffect(() => {
  //   if (!vantaEffect) {
  //     setVantaEffect(
  //       window.VANTA.WAVES({
  //         el: myRef.current,
  //         color: "#231f20",
  //         waveHeight: 20,
  //         shininess: 100,
  //         waveSpeed: 1.5,
  //         zoom: 0.8,
  //       })
  //     );
  //   }
  //   return () => {
  //     if (vantaEffect) vantaEffect.destroy();
  //   };
  // }, [vantaEffect]);
  return (
    <div
      className='page-header'
      style={{
        backgroundColor: "#FFEF00",
      }}
      ref={myRef}
    >
      <Matrix
        fullscreen={true}
        height={2000}
        width={1000}
        style={{
          position: "absolute",
          display: "block",
          width: "100%",
          height: "100%",
          top: 0,
          left: 0,
          right: 0,
          bottom: 0,
          background: "#231f20",
          // overflow: 'hidden',
          zIndex: 0,
        }}
      />
      <Container>
        <Row>
          <Col md={5} xs={12}>
            <img
              src={require(`assets/images/finnosec.png`)}
              width='100%'
              alt='Finnovex'
            />

            <h2 className='text-700 text-g text-center'>8th September 2022</h2>
          </Col>
          <Col lg={1} />
          <Col md={6} xs={12} className={`pb-2 `}>
            {size ? (
              <>
                <h2
                  style={{
                    color: "#fff",
                    fontSize: "30px",
                  }}
                  className='text-700 mt-0 text-h'
                >
                  The Leading Summit on
                  <br />
                  Financial Security and Cyber Resilience
                </h2>
                <br />
                <h5 className='text-700 text-white mt-0'>
                  Join us In-person at CONRAD DUBAI, UAE <br />
                  <span className='text-g'>OR</span> <br />
                  Attend the Virtual Stream Online
                </h5>
              </>
            ) : (
              <>
                <br />
                <h5
                  style={{
                    color: "#fff",
                    // fontSize: '30px',
                  }}
                  className='text-600-a mt-0 px-3 text-center'
                >
                  The Leading Summit on
                  <br />
                  Financial Security and Cyber Resilience
                </h5>

                <h6 className='text-700 text-white text-center mt-0 px-3'>
                  Join us In-person at CONRAD DUBAI, UAE <br />
                  <span className='text-g'>OR</span> <br />
                  Attend the Virtual Stream Online
                </h6>
              </>
            )}
          </Col>
          <Col md={5} xs={12} className='mb-1'>
            <Container fluid>
              <Row>
                <Col lg={12} xs={12} className={`px-${size ? 0 : 1}`}>
                  <Button
                    className='px-3 py-3 mt-3 mr-1 '
                    style={{ width: "99%" }}
                    color='primary'
                    href='/register'
                    size='lg'
                    outline
                  >
                    Register
                  </Button>
                </Col>

                <Col xs={6} className={`px-${size ? 0 : 1}`}>
                  <Button
                    className='px-3 py-1 mt-1 mr-1 '
                    color='primary'
                    style={{ width: "99%" }}
                    href='/request-brochure'
                    size='lg'
                    outline
                  >
                    Download
                    <br /> Brochure
                  </Button>{" "}
                </Col>
                <Col xs={6} className={`px-${size ? 0 : 1}`}>
                  <Button
                    className='px-3 py-1 mt-1 mr-1 '
                    style={{ width: "99%" }}
                    color='primary'
                    href='/sponsors'
                    size='lg'
                    outline
                  >
                    Become a<br /> Sponsor
                  </Button>
                </Col>
              </Row>
            </Container>
          </Col>
        </Row>
      </Container>
      <Modal
        isOpen={modalRegister}
        toggle={toggleRegister}
        className='modal-dialog-centered'
      >
        <ModalHeader toggle={toggleRegister}>
          <b>REGISTER FOR Finnosec</b>
        </ModalHeader>
        <ModalBody>
          {/* 100% complimentry registration for banks, insurance asset and wealth
          management companies
          <br />
          <br />
          <Button
            className='px-3 py-2  my-2 rounded-0'
            style={{ width: "99%" }}
            color='primary'
            href='https://forms.hubilo.com/finnosec_me'
          >
            <p className='m-0 text-700' style={{ color: "#fff" }}>
              Register Now
            </p>
          </Button>
          <br />
          <br />
          For all others
          <br />
          <br />
          <iframe
            src='https://e3702.hubilo.com/ticketing/?iframe=1'
            frameborder='0'
            height='600'
            width='100%'
            title='paid EA'
          ></iframe> */}
        </ModalBody>
      </Modal>
    </div>
  );
}

export default LandingPageHeader;
