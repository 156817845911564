import React from "react";
import { Container, Row, Col, Card, Button } from "reactstrap";
import CountUp from "react-countup";

function ProgressCircle({ percentage }) {
  return (
    <div
      className="ldBar label-center"
      style={{ width: "100%", height: "100%" }}
      data-value={percentage}
      data-type="stroke"
      data-stroke="data:ldbar/res,gradient(270,1,#fcbf30,#ffef00)"
      data-stroke-width="40"
      data-stroke-trail-width="20"
      data-img-size="100,100"
      data-path="M 384.773438 331.523438 C 358.414062 402.992188 304.605469 452.074219 220.273438 481.566406 C 219.972656 481.667969 219.652344 481.757812 219.320312 481.824219 C 218.449219 481.996094 217.5625 481.996094 216.679688 481.820312 C 216.351562 481.753906 216.03125 481.667969 215.734375 481.566406 C 131.3125 452.128906 77.46875 403.074219 51.128906 331.601562 C 28.09375 269.097656 29.398438 200.519531 30.550781 140.019531 L 30.558594 139.683594 C 30.792969 134.484375 30.949219 129.039062 31.035156 123.054688 C 31.222656 110.519531 41.207031 100.148438 53.765625 99.449219 C 87.078125 97.589844 116.34375 91.152344 143.234375 79.769531 C 170.089844 68.402344 193.941406 52.378906 216.144531 30.785156 C 217.273438 29.832031 218.738281 29.828125 219.863281 30.785156 C 242.070312 52.378906 265.921875 68.402344 292.773438 79.769531 C 319.664062 91.152344 348.929688 97.589844 382.246094 99.449219 C 394.804688 100.148438 404.789062 110.519531 404.972656 123.058594 C 405.0625 129.074219 405.21875 134.519531 405.453125 139.683594 C 406.601562 200.253906 407.875 268.886719 384.773438 331.523438 Z M 384.773438 331.523438 "
    ></div>
  );
}

function Stats() {
  let pageHeader = React.createRef();

  return (
    <>
      <div
        style={{
          backgroundColor: "#25211e",
        }}
        className="section"
        data-parallax={true}
        ref={pageHeader}
      >
        <Container>
          <Row>
            <Col lg={12}>
              <h3 className="text-400 text-white text-center mb-4">
                FinnoSec Middle East aims at providing a platform as first step
                in securing the Middle East’s Infrastructure from Cyber
                Vulnerabilities, bring together various stakeholders industry
                leaders and experts to discuss the prevalent security issues
                faced by banking and financial sector through focused panel
                discussions, keynote presentations and roundtable discussions
              </h3>
            </Col>
          </Row>
          <Row className="text-white text-700">
            {content.map((c, i) => (
              <Col lg={3} xs={6} key={i} className="text-center">
                <img
                  src={require(`assets/images/icons/${c.image}.png`)}
                  width="100"
                  alt="icon"
                />
                <h2 className="text-700 mt-0">
                  <CountUp end={c.value} />+
                </h2>
                <h5 className="text-300 mt-0">{c.title}</h5>
              </Col>
            ))}
          </Row>
          <Row style={{ marginTop: "5rem" }} className="justify-content-center">
            <Col
              lg={4}
              className="p-4"
              style={{
                border: "10px solid #fff",
                backgroundColor: "rgba(0,0,0,0.3)",
              }}
            >
              <h2 className="text-700 text-center text-white mt-0">
                <CountUp end={250} duration={2.75} />+ Delegates
              </h2>
            </Col>
          </Row>

          <Row
            className="mt-5 justify-content-center"
            style={{ marginTop: "5rem" }}
          >
            <Col lg={3} xs={6} style={{ padding: "2rem" }}>
              <Card
                style={{ backgroundColor: "transparent", boxShadow: "none" }}
              >
                <ProgressCircle percentage={45} />

                <h3 className="text-700 text-center text-white">C-SUITEs</h3>
              </Card>
            </Col>
            <Col lg={3} xs={6} style={{ padding: "2rem" }}>
              <Card
                style={{ backgroundColor: "transparent", boxShadow: "none" }}
              >
                <ProgressCircle percentage={32} />
                <h3 className="text-700 text-center text-white">
                  VPs/Directors
                </h3>
              </Card>
            </Col>
            <Col lg={3} xs={6} style={{ padding: "2rem" }}>
              <Card
                style={{ backgroundColor: "transparent", boxShadow: "none" }}
              >
                <ProgressCircle percentage={23} />
                <h3 className="text-700 text-center text-white">
                  Heads and Experts
                </h3>
              </Card>
            </Col>
            <Col lg={12} className="text-center">
              <Button
                className="px-3 py-3"
                color="primary"
                size="lg"
                href="/register"
              >
                <h5 className="m-0 text-700" style={{ color: "#000" }}>
                  Register Now
                </h5>
              </Button>
            </Col>
          </Row>
        </Container>
      </div>
    </>
  );
}

export default Stats;

const content = [
  { title: "Trail Blazer Speakers", value: 40, image: 1 },
  { title: "Focus Countries", value: 9, image: 2 },
  { title: "Hours Of Knowledge Sharing", value: 12, image: 3 },
  { title: "Hours Of Networking", value: 8, image: 4 },
];
