import React from "react";
import ProfilePageHeader from "components/Headers/ProfilePageHeader";

import Navbar from "components/Navbar";
import HybridDetail from "components/HybridDetail";
import Footer from "components/Footer";

import ScrollUpButton from "react-scroll-up-button";
import { Helmet } from "react-helmet";

function Speakers() {
  return (
    <div id="outer-container">
      <Helmet>
        <title>
          FINNOSEC MIDDLE EAST - The Leading Summit Financial Security and Cyber
          Resilience
        </title>
        <link
          rel="icon"
          type="image/png"
          sizes="32x32"
          href="https://finnosec.com/32.png"
        />
        <link
          rel="shortcut icon"
          type="image/png"
          sizes="48x48"
          href="https://finnosec.com/48.png"
        />
        <link
          rel="shortcut icon"
          type="image/png"
          sizes="96x96"
          href="https://finnosec.com/96.png"
        />
        <link
          rel="shortcut icon"
          type="image/png"
          sizes="100x100"
          href="https://finnosec.com/100.png"
        />
        <link
          rel="shortcut icon"
          type="image/png"
          sizes="144x144"
          href="https://finnosec.com/144.png"
        />
        <link
          rel="shortcut icon"
          type="image/png"
          sizes="192x192"
          href="https://finnosec.com/192.png"
        />
        <link
          rel="apple-touch-icon"
          sizes="57x57"
          href="https://finnosec.com/57.png"
        />
        <link
          rel="apple-touch-icon"
          sizes="60x60"
          href="https://finnosec.com/60.png"
        />
        <link
          rel="apple-touch-icon"
          sizes="72x72"
          href="https://finnosec.com/72.png"
        />
        <link
          rel="apple-touch-icon"
          sizes="76x76"
          href="https://finnosec.com/76.png"
        />
        <link
          rel="apple-touch-icon"
          sizes="114x114"
          href="https://finnosec.com/144.png"
        />
        <link
          rel="apple-touch-icon"
          sizes="120x120"
          href="https://finnosec.com/120.png"
        />
        <link
          rel="apple-touch-icon"
          sizes="144x144"
          href="https://finnosec.com/144.png"
        />
        <link
          rel="apple-touch-icon"
          sizes="152x152"
          href="https://finnosec.com/152.png"
        />
        <link
          rel="apple-touch-icon"
          sizes="180x180"
          href="https://finnosec.com/180.png"
        />
        <link
          rel="apple-touch-icon"
          sizes="192x192"
          href="https://finnosec.com/192.png"
        />
        <meta
          name="description"
          content="The Leading Summit Financial Security and Cyber Resilience"
        />
        <meta
          name="keywords"
          content="cyber security,breaches,dubai,uae,cyber resilience,cyber,event,hybrid,inperson,live"
        />
        <meta name="Author" content="Finnosec" />
        <link rel="canonical" href="https://www.finnosec.com/hybrid-event" />

        <meta property="og:site_name" content="FinnosecME" />
        <meta property="og:url" content="https://www.finnosec.com/hybrid-event" />
        <meta property="og:image" content={require(`assets/icons/100.png`)} />
      </Helmet>
      <Navbar />
      <ScrollUpButton />
      <ProfilePageHeader title="Hybrid Event" />
      <HybridDetail />
      <Footer />
    </div>
  );
}

export default Speakers;
