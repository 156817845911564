import React from 'react';
import { Container, Row, Col, Button } from 'reactstrap';
import { FacebookShareButton, LinkedinShareButton, TwitterShareButton } from 'react-share';

function news({ news }) {
	const shareUrl = 'www.finnosec.com/finnoseclive/';
	console.log(news);
	return (
		<>
			<Container>
				<Row>
					<Col xs={12}>
						<div className="owner">
							<div className="avatar-big text-center">
								<img
									alt=""
									className="img-no-padding img-responsive img-rounded pt-2"
									src={require(`assets/images/news/${news.image}`)}
								/>
							</div>
						</div>
					</Col>
				</Row>

				<Row>
					<Col className={'ml-auto mr-auto my-2'} md="10">
						<h6>
							Source :{' '}
							<a href="http://www.m2sys.com/blog/financial-services/biometric-technology-banking-security/">
								John Trader
							</a>
						</h6>
					</Col>
				</Row>
				<Row>
					<Col xs={1} className="d-none d-xl-block d-lg-block d-md-block"></Col>
					<FacebookShareButton
						url={`${shareUrl}${news.link}`}
						style={{ padding: 0, margin: 0, display: 'inline !important' }}
						children={
							<Button
								color="neutral"
								outline
								style={{
									padding: '0rem',
									marginRight: '30px',
									marginLeft: '15px',
								}}
							>
								<span style={{ color: '#3b5998', display: 'inline' }}>
									<i style={{ fontSize: '50px' }} className="fa fa-facebook-square float-left" />
								</span>
							</Button>
						}
					/>

					<LinkedinShareButton
						url={`${shareUrl}${news.link}`}
						style={{ padding: 0, margin: 0, display: 'inline !important' }}
						children={
							<Button
								color="neutral"
								outline
								style={{
									padding: '0rem',
									marginRight: '30px',
								}}
							>
								<span style={{ color: '#0976b4' }}>
									<i style={{ fontSize: '50px' }} className="fa fa-linkedin-square float-left" />
								</span>
							</Button>
						}
					/>
					<TwitterShareButton
						url={`${shareUrl}${news.link}`}
						style={{ padding: 0, margin: 0, display: 'inline !important' }}
						children={
							<Button
								color="neutral"
								outline
								style={{
									padding: '0rem',
									marginRight: '30px',
								}}
							>
								<span style={{ color: '#55acee' }}>
									<i style={{ fontSize: '50px' }} className="fa fa-twitter-square float-left" />
								</span>
							</Button>
						}
					/>
				</Row>

				<Row>
					<Col className={'ml-auto mr-auto text-justify'} md="10">
						<br />
						<p>
							If you haven’t heard the news, more banks are adopting biometric technology for customer
							authentication to increase security. Banks all around the world have been searching for more
							advanced technologies such as biometrics for better protection against identity theft,
							fraud, and cyber-crime often perpetuated by stolen customer identities.
						</p>
						<p>
							Biometrics acts as a strong authentication tool to help secure transactions and also helps
							to increase customer trust and improve brand reputation. Biometrics in banking has evolved
							so rapidly across the world that it raises the question as to whether the technology is
							going to entirely take over banking security. We believe it is.
						</p>
						<br />

						<h3>
							<b>What is Biometric Technology?</b>
						</h3>

						<p>
							Biometrics in banking are automated methods of recognizing customers through their
							physiological characteristics and traits such as fingerprints, finger vein patterns, iris,
							and voice recognition. Biometric characteristics are unique for every individual on the
							planet and extremely difficult to spoof or forge, which is why the technology is commonplace
							in high security government operations like border control, prison management, and national
							ID. Biometric technology is also quickly replacing passwords and token-based electronic
							access, signature-based branch service access, and personal identification number (PIN)
							based access in mobile banking and at ATMs.
						</p>
						<br />
						<h3>
							<b>Why Biometrics for Banking Security?</b>
						</h3>

						<p>
							Many banks worldwide are already using biometrics with their banking system software to
							authenticate employees and customers — 52 percent being located in Asia. In fact, Japan has
							more than an estimated 15 million customers using biometric authentication for banking
							transactions. Banks in Mexico, South America, Africa, and the Middle East are also moving
							towards the use of biometric identification technology because of its popularity with
							consumers, and unique ability to offer more security than traditional PINs and passwords.
						</p>
						<br />
						<p>
							Besides ensuring security for transaction authentication and employee and customer
							identification, another reason why banks are quickly adopting biometric technology is
							because of increasing customer demand. Many people now prefer biometrics for banking
							security over more traditional forms of authentication. Experts also point to the fact that
							biometrics is the most secure customer authentication method available.
						</p>
						<br />

						<h3>
							<b>Biometrics for Know Your Customer – (KYC) Management</b>
						</h3>

						<p>
							In some countries, banks are required to follow Know Your Customer (KYC) policies regulated
							by the government. The main objectives of KYC initiatives are to prevent criminals from
							using banking channels for money laundering and fraudulent activities. However, financial
							and banking institutions are looking at KYC controls to not only meet regulatory
							requirements but to also cut down fraud and improve overall customer relationships across
							their products and channels. The major benefits of using biometrics for banking security
							through KYC Management are:
						</p>
						<ul>
							<li>Accurate identification</li>
							<li>Quick verification</li>
							<li>Optimum security</li>
							<li>Convenience and ease of use</li>
						</ul>
						<br />

						<h3>
							<b>How Banks Can Use Biometrics</b>
						</h3>

						<p>
							To sum up the importance of biometrics in banking, here are some ways banks can use
							biometric technology to improve banking security and services, and better protect customer
							assets:
						</p>
						<ul>
							<li>Biometrics in branch banking</li>
							<li>Biometrics in ATMs</li>
							<li>Biometrics for internet banking</li>
							<li>Biometrics in mobile banking</li>
							<li>Single sign-on (SSO) form more effective password management</li>
						</ul>
						<br />
						<h3>
							<b>Conclusion</b>
						</h3>

						<p>
							Due to increasing customer demand for banking security, banks are providing more convenient
							and secure banking services through the use of biometric technology. Biometric technology,
							integrated with an existing bank software solution is empowering banks to deploy the highest
							level of authentication security possible. We can safely assume that it is just a matter of
							time that the entire global banking industry will eventually shift to biometric technology
							for authentication and security.
						</p>
						<br />
					</Col>
				</Row>
			</Container>
		</>
	);
}

export default news;
