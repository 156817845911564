import React from "react";
import {
  Container,
  Collapse,
  Navbar,
  NavbarBrand,
  Nav,
  NavItem,
  NavLink,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from "reactstrap";
import { Link } from "react-router-dom";
import classnames from "classnames";
import AnchorLink from "react-anchor-link-smooth-scroll";

function NavbarDiv({ home }) {
  console.log("home");
  const [navbarColor, setNavbarColor] = React.useState("");
  const [navbarCollapse, setNavbarCollapse] = React.useState(false);

  const toggleNavbarCollapse = () => {
    setNavbarCollapse(!navbarCollapse);
    document.documentElement.classList.toggle("nav-open");
  };

  React.useEffect(() => {
    const updateNavbarColor = () => {
      if (
        document.documentElement.scrollTop > 50 ||
        document.body.scrollTop > 50
      ) {
        setNavbarColor("");
      } else if (
        document.documentElement.scrollTop < 300 ||
        document.body.scrollTop < 300
      ) {
        setNavbarColor("");
      }
    };

    window.addEventListener("scroll", updateNavbarColor);

    return function cleanup() {
      window.removeEventListener("scroll", updateNavbarColor);
    };
  });

  return (
    <>
      <Navbar
        className={classnames("fixed-top border-bottom", navbarColor)}
        color-on-scroll="300"
        expand="lg"
      >
        <Container fluid>
          <div className="navbar-translate">
            <NavbarBrand
              data-placement="bottom"
              to="/"
              target="_blank"
              className="p-0"
              tag={Link}
            >
              <img
                src={require(`assets/images/logo/footerlogo.png`)}
                className="pr-2 border-right"
                width="220px"
                style={{ maxWidth: "250px" }}
                alt="logo"
              />
            </NavbarBrand>
            <a
              href="https://finnovex.com"
              target="_blank"
              rel="noopener noreferrer"
            >
              <img
                src={require(`assets/images/logo/finnovexdark.png`)}
                className="pl-2"
                width="120px"
                style={{ maxWidth: "150px" }}
                alt="logo"
              />
            </a>
            <button
              aria-expanded={navbarCollapse}
              className={classnames("navbar-toggler navbar-toggler", {
                toggled: navbarCollapse,
              })}
              onClick={toggleNavbarCollapse}
            >
              <span className="navbar-toggler-bar bar1 text-white" />
              <span className="navbar-toggler-bar bar2" />
              <span className="navbar-toggler-bar bar3" />
            </button>
          </div>
          <Collapse
            className="justify-content-end"
            navbar
            isOpen={navbarCollapse}
          >
            <Nav navbar>
              {menu.map((link, i) =>
                !link.items ? (
                  <NavItem key={i}>
                    {window.location.pathname === "/" && link.page === "/" ? (
                      <AnchorLink
                        offset="100"
                        href={`#${link.link}`}
                        onClick={toggleNavbarCollapse}
                        className="nav-link navbar-item-custom text-400 text-decoration-none"
                      >
                        {link.name}
                        <hr className="m-0 mt-1 p-0" align="center" />
                      </AnchorLink>
                    ) : link.page ? (
                      <a
                        href={`/#${link.link}`}
                        className="nav-link navbar-item-custom text-400"
                      >
                        {link.name}
                        <hr className="m-0 mt-1 p-0" align="center" />
                      </a>
                    ) : (
                      <a
                        href={`${link.link}`}
                        className="nav-link navbar-item-custom text-400"
                      >
                        {link.name}
                        <hr className="m-0 mt-1 p-0" align="center" />
                      </a>
                    )}
                  </NavItem>
                ) : (
                  <UncontrolledDropdown nav inNavbar key={i}>
                    <DropdownToggle nav caret className="navbar-item-custom">
                      {link.name}
                    </DropdownToggle>
                    <DropdownMenu right>
                      {link.items.map((i) => (
                        <DropdownItem href={i.link} key={i.link}>
                          {i.name}
                        </DropdownItem>
                      ))}
                    </DropdownMenu>
                  </UncontrolledDropdown>
                )
              )}

              <NavItem>
                <NavLink
                  to="/awards"
                  tag={Link}
                  className="btn btn-primary"
                  style={{ opacity: "1" }}
                >
                  Award Nomination
                </NavLink>
              </NavItem>
            </Nav>
          </Collapse>
        </Container>
      </Navbar>
    </>
  );
}

export default NavbarDiv;
const menu = [
  { name: "HOME", link: "home", page: "/", items: undefined, grid: 1 },
  {
    name: "ABOUT",
    link: "about",
    page: "/",
    items: [
      { name: "FINNOVEX GLOBAL SERIES", link: "https://finnovex.com" },
      { name: "SAFETY MEASURES AT FINNOSEC", link: "/safety" },
    ],
    grid: 1,
  },
  // { name: "SPEAKERS", link: "speakers", page: "/", items: undefined, grid: 1 },
  {
    name: "#FINNOSECLIVE",
    link: "/finnosecLive",
    page: undefined,
    items: undefined,
    grid: 1,
  },
  {
    name: "Speakers",
    link: "speakers",
    page: "/",
    items: undefined,
    grid: 1,
  },
  {
    name: "PARTNERS",
    link: "sponsors",
    page: "/",
    items: [
      { name: "WHY SPONSOR", link: "/sponsors" },
      { name: "SPONSORS AND PARTNERS", link: "/sponsors-partners" },
    ],
    grid: 1,
  },
  {
    name: "DELEGATES",
    link: "delegates",
    page: null,
    items: [
      { name: "WHY ATTEND", link: "/delegates" },
      { name: "WHO SHOULD ATTEND", link: "/delegates/2" },
      { name: "DOWNLOAD DELEGATE LIST", link: "/register" },
      { name: "REGISTER", link: "/register" },
    ],
    grid: 1,
  },
];
