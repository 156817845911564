import React from "react";
import LandingPageHeader from "components/Headers/LandingPageHeader";
import TextGrid from "components/TextGrid";
import Event from "components/Event";
import Navbar from "components/Navbar";
import Footer from "components/Footer";

import Spotlight from "components/Spotlight";
import Sponsor from "components/Sponsor";

import Exibex from "components/Exibex";
import Awards from "components/Awards";

import Hybrid from "components/Hybrid";
import Speaker from "components/Speaker";

// import Register from 'components/Register';

import { newSponsorPage, speakers } from "./content";

import ScrollUpButton from "react-scroll-up-button";

function Home() {
  return (
    <div id='outer-container'>
      <Navbar page='home' />
      <ScrollUpButton />
      <section id='home' />
      <LandingPageHeader />
      <section id='about' />
      <TextGrid />
      <Hybrid />
      <Event />

      <Spotlight />

      <section id='speakers' />
      <Speaker
        speakers={speakers.filter((s) => !s.keynote)}
        keynote={speakers.filter((s) => s.keynote)[0]}
      />
      <section id='awards' />
      <Awards />
      <section id='sponsors' />
      <Sponsor sponsors={newSponsorPage} />
      <section id='finnosecLive' />
      <Exibex />
      <Footer />
    </div>
  );
}

export default Home;
