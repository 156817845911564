import React from "react";
import { Container, Row, Col, Button } from "reactstrap";
import {
  FacebookShareButton,
  LinkedinShareButton,
  TwitterShareButton,
} from "react-share";

function news({ news }) {
  const shareUrl = "www.finnosec.com/finnoseclive/";
  console.log(news);
  return (
    <>
      <Container>
        <Row>
          <Col className={"ml-auto mr-auto my-2"} md="10">
            <h6>
              Source :{" "}
              <a href="https://securityintelligence.com/articles/creating-roadmap-toward-cyber-resilience/">
                creating-roadmap-toward-cyber-resilience
              </a>
            </h6>
          </Col>
        </Row>
        <Row>
          <Col xs={1} className="d-none d-xl-block d-lg-block d-md-block"></Col>
          <FacebookShareButton
            url={`${shareUrl}${news.link}`}
            style={{ padding: 0, margin: 0, display: "inline !important" }}
            children={
              <Button
                color="neutral"
                outline
                style={{
                  padding: "0rem",
                  marginRight: "30px",
                  marginLeft: "15px",
                }}
              >
                <span style={{ color: "#3b5998", display: "inline" }}>
                  <i
                    style={{ fontSize: "50px" }}
                    className="fa fa-facebook-square float-left"
                  />
                </span>
              </Button>
            }
          />

          <LinkedinShareButton
            url={`${shareUrl}${news.link}`}
            style={{ padding: 0, margin: 0, display: "inline !important" }}
            children={
              <Button
                color="neutral"
                outline
                style={{
                  padding: "0rem",
                  marginRight: "30px",
                }}
              >
                <span style={{ color: "#0976b4" }}>
                  <i
                    style={{ fontSize: "50px" }}
                    className="fa fa-linkedin-square float-left"
                  />
                </span>
              </Button>
            }
          />
          <TwitterShareButton
            url={`${shareUrl}${news.link}`}
            style={{ padding: 0, margin: 0, display: "inline !important" }}
            children={
              <Button
                color="neutral"
                outline
                style={{
                  padding: "0rem",
                  marginRight: "30px",
                }}
              >
                <span style={{ color: "#55acee" }}>
                  <i
                    style={{ fontSize: "50px" }}
                    className="fa fa-twitter-square float-left"
                  />
                </span>
              </Button>
            }
          />
        </Row>

        <Row>
          <Col className={"ml-auto mr-auto text-justify"} md="10">
            <p>What is&nbsp;cyber resilience?&nbsp;</p>
            <br />
            <p>
              According to&nbsp;
              <a href="https://www.ibm.com/account/reg/us-en/signup?formid=urx-45839&amp;_ga=2.1023858.255029181.1595266313-545301124.1591239208&amp;cm_mc_uid=78530529166215859305703&amp;cm_mc_sid_50200000=83358031595266312380">
                IBM Security&rsquo;s 2020 Cyber Resilient Organization Report
              </a>
              , a cyber resilient organization is one that &ldquo;more
              effectively prevents, detects, contains and responds to a myriad
              of serious threats against data, applications and IT
              infrastructure.&rdquo;&nbsp;
            </p>
            <br />
            <p>
              In a more colloquial sense, the &ldquo;further along in&nbsp;the
              game&rdquo; the organization is, the better positioned the
              organization is to being resilient. An emphasis on
              &ldquo;position&rdquo; is important here, particularly when
              creating your roadmap toward cyber resilience.
            </p>
            <br />
            <p>
              Why?&nbsp;Being in a better position is no guarantee of better
              execution. Position and execution should be treated as mutually
              exclusive, but also complementary to each other when creating a
              roadmap.
            </p>
            <br />
            <p>
              Here are four major steps to help you plan, create and execute a
              roadmap.
            </p>
            <br />
            <h2>Step 1: Understand What Your Resources Are</h2>
            <p>
              Keep in mind resources are not purely technological.&nbsp;Your
              resource mix is made of up technological and human capital,
              including how they operate and interact with each other.&nbsp;You
              can have the latest-and-greatest tools all properly
              configured,&nbsp;but, if you do not have an experienced and
              properly-trained team managing and maintaining these tools, these
              tools may never see the light of day.{" "}
            </p>
            <br />
            <p>
              You also need to be cognizant that all current and future
              information security tools could lead you into a&nbsp;
              <a href="https://securityintelligence.com/articles/will-the-future-of-authentication-run-into-a-privacy-wall/">
                privacy
              </a>
              &nbsp;jam if use is not thought through.&nbsp;Anything from
              real-monitoring issues to holding too much (or unnecessary)
              amounts of personally identifiable information
              (PII)&nbsp;increases the liability side of your balance sheet.
            </p>
            <br />
            <p>
              Therefore, it&rsquo;s the totality of your resource mix that will
              help determine your current position.&nbsp;So how do you determine
              your current position?&nbsp;It all begins with risk management.
              <br />
              &nbsp;
            </p>
            <br />
            <h2>Step 2: Define&nbsp;Your Risk Posture</h2>
            <p>
              Risk management is by no means a science, even though a lot of
              science goes into it.&nbsp;As some environments become more
              complex, their likelihood for fragility increases, notes risk
              management expert Nassim Nicholas Taleb in his book &ldquo;
              <a
                href="https://www.amazon.com/Antifragile-Things-That-Disorder-Incerto/dp/0812979680"
                target="_blank"
                rel="noopener noreferrer"
              >
                Antifragile: Things That Gain from Disorder
              </a>
              .&rdquo;&nbsp;
            </p>
            <br />
            <p>
              The 2020 Cyber Resilient Organization Report found that too many
              tools weaken cyber resilience, specifically that &ldquo;excessive
              use of disconnected tools creates complex environments, which can
              inhibit efficiency.&rdquo;&nbsp;
            </p>
            <br />
            <p>
              When you define your risk posture and consider integration
              solutions, employ some of Visa founder Dee Hock&rsquo;s philosophy
              into your planning.
            </p>
            <br />
            <p>
              &ldquo;Simple, clear purpose and principles give rise to complex
              intelligent behavior.&nbsp;Complex rules and regulations give rise
              to simple stupid behavior,&rdquo; Hock has said.&nbsp;
            </p>
            <br />
            <p>
              If you are over engineering your risk management and resilience
              planning, you may very well be building a house of cards and
              there&rsquo;s a wolf out there ready to blow it
              down.&nbsp;Therefore, you should consider your risk posture
              options, such as:
            </p>
            <br />
            <ul type="disc">
              <li>Risk acceptance</li>
              <li>Risk transfer</li>
              <li>Risk avoidance</li>
              <li>Risk mitigation</li>
              <li>
                Risk deferral (Delaying action on a risk to capture a time
                sensitive business opportunity, for example)
              </li>
              <li>
                Risk exploitation (Taking advantage of the risk inherent in
                something new &mdash; higher than expected demand for a new
                product, for example &mdash; by planning in advance how to meet
                the demand)
              </li>
            </ul>
            <p>
              It is a crude metaphor, but managing your cyber risk increasingly
              becomes more like managing your investment portfolio.&nbsp;It is
              tailored to you and your expectations. But if you are not on top
              of what&rsquo;s going on, increasing complexity could lead to
              increasing&nbsp;volatility.&nbsp;That means your current and
              future outlooks could quickly be thrown into jeopardy. More
              importantly, if you are hoping to maximize business efficiency in
              your operations using this new gadgetry, then you may be setting
              yourself up for a colossal loss on the downturn or, a massive data
              breach that is nearly impossible to recover from could occur.{" "}
            </p>
            <br />
            <p>
              Part of resilience also includes hedging.&nbsp;This is your
              standard business continuity planning, asset management, backing
              up, doing the basics, disaster recovery and other things that
              could fall into&nbsp;
              <a href="https://securityintelligence.com/an-apple-a-day-wont-improve-your-security-hygiene-but-a-cyber-doctor-might/">
                improving your cyber hygiene
              </a>
              .&nbsp;You need to be able to weather a storm and avoid going bust
              (or, on life support) on one bad hit.&nbsp;
            </p>
            <br />
            <h2>Step 3: Get in the Right Frame of Mind</h2>
            <p>
              Data is a form of valuable currency in today&rsquo;s
              environment.&nbsp;
              <a
                href="https://georgeplatsis.com/2018/01/09/treat-your-data-like-cash/"
                target="_blank"
                rel="noopener noreferrer"
              >
                Treating your data like cash
              </a>
              &nbsp;is, therefore, not unreasonable at all.&nbsp;In fact,
              treating data like money is a prudent course of action when
              creating your cyber resilience roadmap.&nbsp;Poor handling of your
              data could actually lead to a real loss of cash and revenue.
              Making that leap requires a mindset change, as&nbsp;
              <a href="https://securityintelligence.com/posts/exploring-ponemon-data-after-4-years-is-security-satiated-with-false-confidence/">
                overconfidence
              </a>
              &nbsp;could be part of an organization&rsquo;s undoing.{" "}
            </p>
            <br />
            <p>
              The 2020 Cyber Resilient Organization Report highlights five
              reasons why &ldquo;high performers&rdquo; are more cyber
              resilient. High performers ranked better in the following
              areas:&nbsp;
            </p>
            <br />
            <ul>
              <li>
                Leaders recognize that automation, machine learning, artificial
                intelligence (AI) and orchestration strengthen&nbsp;cyber
                resilience
              </li>
              <li>
                A strong privacy posture is important to achieving cyber
                resilience
              </li>
              <li>
                Leaders recognize enterprise risks affect cyber resilience
              </li>
              <li>
                Using cybersecurity tools that are interoperable across vendors
                helps increase the ability to respond more effectively to
                cyberattacks
              </li>
              <li>Leaders recognize cyber resilience affects revenue</li>
            </ul>
            <p>
              These points support the need for understanding your resource mix,
              privacy implications of tools used, the balance between technology
              and interoperability and risk management.&nbsp;
            </p>
            <br />
            <h2>Step 4: Step Up to the Challenge</h2>
            <p>
              The final step is action. There are three fundamental questions
              you need to ask yourself,&nbsp;
              <a
                href="https://www.bbc.com/worklife/article/20160406-why-these-three-questions-can-solve-any-problem"
                target="_blank"
                rel="noopener noreferrer"
              >
                cites Sidney Finkelstein
              </a>
              , if you truly want to be cyber resilient:&nbsp;
            </p>
            <br />
            <ul>
              <li>Are you willing to change what you have been doing?</li>
              <li>Can you think of a better strategy than the status quo?</li>
              <li>Can you execute on your chosen solution?</li>
            </ul>
            <p>
              If you can answer &ldquo;yes&rdquo; to all three, you&rsquo;re
              ready to go.&nbsp;Steps one through three are designed to get you
              in the right position. Step four brings together strategic,
              operational and tactical considerations in order to achieve best
              practices.
            </p>
            <br />
            <p>According to the Cyber Resilient Report you should:</p>
            <br />
            <ul>
              <li>
                Implement an enterprise-wide cybersecurity incident response
                plan to minimize business disruption
              </li>
              <li>
                Tailor response plans to specific attacks in your industry
              </li>
              <li>
                Embrace interoperability to increase visibility and reduce
                complexity
              </li>
              <li>Invest in technologies to accelerate incident response</li>
              <li>Align your security and privacy teams</li>
              <li>
                Formalize C-level/board reporting to raise the visibility of the
                organization&rsquo;s cyber resilience
              </li>
            </ul>
            <p>
              The last two points are particularly important because, if left
              unaddressed, they become critical barriers between your
              stakeholders. You need a a common language every team member can
              understand. Stakeholders may not always operate in the same
              timescales.&nbsp;For example, your C-Suite may be focused on
              long-term growth. The security team may want to focus on an
              immediate threat. And, the privacy team want to address the
              ongoing legal ramifications of a breach.&nbsp;But there needs to
              be an area that brings all stakeholders into alignment:&nbsp;
              <a href="https://securityintelligence.com/the-language-of-business-where-the-board-of-directors-and-security-leaders-can-meet/">
                the language of business
              </a>
              .&nbsp;Having that candid &ldquo;business talk&rdquo; between the
              stakeholders sets the stage for:&nbsp;
            </p>
            <br />
            <ul>
              <li>What resources are needed</li>
              <li>
                What amount of risk the organization is willing to take on
              </li>
              <li>
                What cultural changes need to happen within the organization
              </li>
              <li>How to execute the cyber resilience plan</li>
            </ul>
            <h2>Tools to Help You Get Started</h2>
            <p>
              The&nbsp;
              <a
                href="https://www.nist.gov/cyberframework/framework"
                target="_blank"
                rel="noopener noreferrer"
              >
                NIST Cybersecurity Framework
              </a>
              &nbsp;(NIST CSF) is a great starting point, because it is scalable
              to the size of the organization.&nbsp;Its adoption is relatively
              widespread. First, review the functions:&nbsp;identify, protect,
              detect, respond and recover. Then, check off what you have done
              and what needs to be done, with the added bonus of being able to
              really dig in.&nbsp;
            </p>
            <br />
            <p>
              NIST CSF also provides deeper information around categories and
              subcategories. The deeper you go, the more specific the
              control.&nbsp;That&rsquo;s where NIST special publications come in
              handy. For example, need help on your risk assessment? Look at
              NIST SP 800-30,&nbsp;
              <a
                href="https://csrc.nist.gov/publications/detail/sp/800-30/rev-1/final"
                target="_blank"
                rel="noopener noreferrer"
              >
                Guide for Conducting Risk Assessments
              </a>
              .&nbsp;Going to be handling government information at your
              organization? NIST SP 800-53,&nbsp;
              <a
                href="https://csrc.nist.gov/publications/detail/sp/800-53/rev-4/final"
                target="_blank"
                rel="noopener noreferrer"
              >
                Security and Privacy Controls for Federal Information Systems
                and Organizations
              </a>
              &nbsp;helps specific controls you need to keep an eye out
              for.&nbsp;Is privacy your main concern?&nbsp;Plus, you can review
              the new NIST Privacy Framework,&nbsp;
              <a
                href="https://www.nist.gov/privacy-framework/privacy-framework"
                target="_blank"
                rel="noopener noreferrer"
              >
                A Tool for Improving Privacy through Enterprise Risk Management,
              </a>
              &nbsp;which operates&nbsp;just like the NIST CSF with functions,
              categories and subcategories. However, keep in mind that using
              tools can be a futile attempt without knowing your
              organization&rsquo;s risk tolerances.
            </p>
            <br />
            <h2>Building a Roadmap to Weather the Storm</h2>
            <p>
              The 2020 Cyber Resilient Report clearly states that challenges
              still remain.&nbsp;Therefore, remember cyber resilience is a team
              effort, where every person who touches your organization&rsquo;s
              network has a role and responsibility. Don&rsquo;t forget to
              identify team roles and responsibilities as part of building your
              cyber resilience roadmap. Focus on employee training and testing.
              Doing so will better position your organization from a resilience
              perspective and better prepare you to execute on a stronger
              position, taking advantage of all your previous hard work.&nbsp;
            </p>
            <br />
            <p>
              If you still are unsure of how to start building or modifying your
              cyber resilience roadmap, take a look at the&nbsp;
              <a
                href="https://www.eisenhower.me/eisenhower-matrix/#:~:text=The%20Eisenhower%20Matrix%2C%20also%20referred%20to%20as%20Urgent-Important,should%20either%20delegate%20or%20not%20do%20at%20all."
                target="_blank"
                rel="noopener noreferrer"
              >
                Eisenhower Matrix
              </a>
              . The matrix will help you prioritize and delegate tasks across
              your environment.&nbsp;
            </p>
            <br />
          </Col>
        </Row>
      </Container>
    </>
  );
}

export default news;
