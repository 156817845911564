import React from "react";
import { Container, Row, Col, Button } from "reactstrap";
import {
  FacebookShareButton,
  LinkedinShareButton,
  TwitterShareButton,
} from "react-share";

function news({ news }) {
  const shareUrl = "www.finnosec.com/finnoseclive/";
  console.log(news);
  return (
    <>
      <Container>
        <Row>
          <Col className={"ml-auto mr-auto my-2"} md="10">
            <h6>
              Source :{" "}
              <a href="https://ciso.economictimes.indiatimes.com/news/top-10-things-that-will-drive-banks-in-2020/73064020">
              https://ciso.economictimes.indiatimes.com/news/top-10-things-that-will-drive-banks-in-2020/73064020
              </a>
            </h6>
          </Col>
        </Row>
        <Row>
          <Col xs={1} className="d-none d-xl-block d-lg-block d-md-block"></Col>
          <FacebookShareButton
            url={`${shareUrl}${news.link}`}
            style={{ padding: 0, margin: 0, display: "inline !important" }}
            children={
              <Button
                color="neutral"
                outline
                style={{
                  padding: "0rem",
                  marginRight: "30px",
                  marginLeft: "15px",
                }}
              >
                <span style={{ color: "#3b5998", display: "inline" }}>
                  <i
                    style={{ fontSize: "50px" }}
                    className="fa fa-facebook-square float-left"
                  />
                </span>
              </Button>
            }
          />

          <LinkedinShareButton
            url={`${shareUrl}${news.link}`}
            style={{ padding: 0, margin: 0, display: "inline !important" }}
            children={
              <Button
                color="neutral"
                outline
                style={{
                  padding: "0rem",
                  marginRight: "30px",
                }}
              >
                <span style={{ color: "#0976b4" }}>
                  <i
                    style={{ fontSize: "50px" }}
                    className="fa fa-linkedin-square float-left"
                  />
                </span>
              </Button>
            }
          />
          <TwitterShareButton
            url={`${shareUrl}${news.link}`}
            style={{ padding: 0, margin: 0, display: "inline !important" }}
            children={
              <Button
                color="neutral"
                outline
                style={{
                  padding: "0rem",
                  marginRight: "30px",
                }}
              >
                <span style={{ color: "#55acee" }}>
                  <i
                    style={{ fontSize: "50px" }}
                    className="fa fa-twitter-square float-left"
                  />
                </span>
              </Button>
            }
          />
        </Row>

        <Row>
          <Col className={"ml-auto mr-auto text-justify"} md="10">
            <p>
              The last year of the decade is ending with dramatic changes in the
              banking sector. From the amalgamation of banks to the liquidity
              crisis, and low credit demand to the gigantic defaults. Bankers
              went through many changes and challenges. Will the situation
              change in 2020? Will banks turnaround in 2020? What will drive the
              Indian banks in 2020? How will be the Banking jobs in 2020 be?
              What will be the opportunities and challenges for banks in 2020?
              Here is what ETBFSI has discovered.
            </p>
            <br />
            <p>
              According to Chinese Zodiac, 2020 is an interesting number and
              bankers are hoping that it will be a special year because they
              have taken the toughest decisions last year, and the result is
              awaited in 2020. Whether it is keeping high provisions,
              controlling liquidity, not taking any exposure in NBFCs, or
              adopting new technologies.
              <br />
              <br />
              2018-19 was the year of hope and FY21 will be a year of happiness,
              said Rajnish Kumar two years back. In a recent interview with
              ETBFSI, Rajkiran Rai, MD&amp;CEO of Union Bank CMD said,
              &ldquo;2020 will be positive. We have already hit the
              bottom.&rdquo;
              <br />
              <br />
              <strong>
                1. Rise of&nbsp;
                <a href="https://ciso.economictimes.indiatimes.com/tag/credit+growth">
                  Credit Growth
                </a>
              </strong>
              &nbsp;&mdash; According to a BCG report, retail banking will grow
              rapidly in 2020. Mortgages to grow fast and will cross Rs 40
              trillion by 2020. But currently, the credit demand is low on both
              corporate and retail sides.&nbsp;
              <a href="https://ciso.economictimes.indiatimes.com/tag/rbi">
                RBI
              </a>
              &nbsp;has assured that India&rsquo;s financial system is stable
              despite weak growth and projected that demand will rise in 2020.
              But according to ratings agency ICRA, the credit growth in 2020
              will be 50 years low. Major sectors such as auto, real estate are
              down and banks took cautious calls by preserving the liquidity.
              What gives hope in 2020 is that the government&rsquo;s affordable
              housing scheme, reduction in corporate tax, GST tax revision,
              RBI&rsquo;s 134 bps rate reduction and finance ministry&rsquo;s
              push to empower bankers. This will certainly help to boost credit
              growth.
              <br />
              <br />
            </p>
            <br />
            <p>
              <strong>2. Recovery from Bad Loans: IBC &amp; NCLT</strong>
              &nbsp;&mdash; Bankers will carry the baggage of bad loans in 2020
              as well. IBC has made debtor responsible and bankers are feeling
              strong like never before. Despite being a special law and deadline
              to close the cases, resolution is not easy. Currently, as per NCLT
              data, more than 10 thousand cases under IBC are pending before
              NCLT.The backlog of NCLT will continue in 2020 as well. In a
              lighter note, Rajnish Kumar of SBI once said, that every morning
              he prays to god to resolve the three large accounts that are stuck
              at NCLT. Kumar was referring to &mdash; Essar Steel, Bhushan Power
              &amp; Steel, and Alok Industries &mdash; where SBI is expected to
              recover Rs 16000 crore. If there are more defaults, more cases
              will be filed in NCLT and Banks will not have any other option but
              to wait and pray for the resolution.
              <br />
              <br />
              <strong>3. PSB: Merger execution and stake reduction</strong>
              &nbsp;&mdash; Amalgamation of PSBs was one of the bold decisions
              that the government took in 2019. They merged 10 banks into 4 and
              the total number of PSBs reduced from 27 to 12. Financial experts
              have supported the idea of merging banks but the question is how
              will they execute? The government has recapitalised banks but the
              change in management, infrastructural issues, transfer of senior
              officers are still in the process. Also, the government is very
              slow in recruiting the heads of the PSB&rsquo;s. What is more
              important to see in 2020 is how the execution of the merged banks
              pan out. What will also drive the PSBs in 2020 is whether the
              government reduces its holding in PSBs to 51%.
              <br />
              <br />
            </p>
            <br />
            <p>
              <strong>4. Private banks: Building business</strong>&nbsp;&mdash;
              Private banks, too, went through challenging times. Major private
              banks such as ICICI Bank, Axis Bank, and Yes bank went through a
              big reshuffle. All three banks got new CEOs. Also, the CEO of HDFC
              is going to retire in 2020. Every private bank has a different
              story. In 2020, it is crucial to see how do they deal with the
              NPAs, raise funds and generate credit demand. The major challenge
              for the new management in the banks is to make a sustainable and
              profitable business.
              <br />
              <br />
              <strong>5. Improving Customer Service</strong>&nbsp;&mdash; For
              bankers asset story is painful, hence they are focussing more on
              deposits. In 2020 bankers will have to find innovative ways to
              attract customers and more importantly improve their customer
              service. Bankers are already using fancy words such as,
              &lsquo;customer delight&rsquo;, &lsquo;customer experience&rsquo;
              to attract customers, better they adopt the same. Customers expect
              banking should be as seamless as booking a cab on Uber and
              ordering food on Swiggy. Most of the banks are trying hard to
              build innovative UI and UX for their mobile apps. In 2020, retail
              banking will be driven by customer service.
              <br />
              <br />
              <strong>6. Innovations in 2020</strong>&nbsp;&mdash; Banking and
              the financial sector is the flag bearer of tech innovation. From
              payment transfer to obtaining the loans is quite easy on mobile
              apps today. In 2020, tech innovation in banking will significantly
              focus on voice and video banking. Currently, bots are being used
              to serve the customers but they are still not up to the mark.
              Experts believe most of the technologies will mature in 2020.
              Especially on the technology front, Open API will be well accepted
              in the banking sector in 2020. Also, Data and Analytics,
              Artificial Intelligence,Cognitive Technology,Blockchain
              Technology, Robotics Process Automation will be widely accepted by
              banks and financial institutions.
              <br />
              <br />
              <strong>7. Collaboration and partnerships</strong>
              &nbsp;&mdash;&nbsp;
              <a href="https://ciso.economictimes.indiatimes.com/tag/fintechs">
                FinTechs
              </a>
              &nbsp;made a big difference in banking and finance space.
              Initially, there was a fear that FinTechs will eat banks&rsquo;
              lunch. But gradually FinTechs have began partnering with banks.
              Almost all the banks have partnered with FinTechs. FinTechs are
              bringing in the innovation that banks couldn&rsquo;t. In 2020
              banks will collaborate and partner with market forces starting
              from FinTechs to E-commerce players for different segments of
              their businesses.
              <br />
              <br />
              <strong>8. Regulations</strong>&nbsp;&mdash; RBI has already
              issued guidelines for regulatory Sandbox and &lsquo;on tap
              license&rsquo; and payments. In 2020, new companies will enter
              payment, small finance bank, and FinTech space. Regulators' agenda
              is last-mile connectivity and financial inclusion. Considering the
              Fraud at PMC bank, RBI has also decided to keep a watch on UCBs.
              Europe' challenger bank model and Neo banks like Xinja and N26 are
              rising. RBI may think about it.
              <br />
              <br />
              <strong>
                9.&nbsp;
                <a href="https://ciso.economictimes.indiatimes.com/tag/cybersecurity">
                  Cybersecurity
                </a>
              </strong>
              &nbsp;&mdash; One of the major worries for any bank is
              cybersecurity. After Cosmos Bank lost Rs 90 crore in a cyber
              attack, all have become more watchful. According to survey, every
              minute some 100 people are hacked globally. Hackers are using the
              most innovative tools to attract customers. In 2020, the biggest
              challenge for banks is to protect themselves and their customers
              from cyber frauds.
              <br />
              <br />
              <strong>10. Banking Jobs</strong>&nbsp;&mdash; Job market is hit
              by the economic slowdown largely but jobs in the banking sector
              will remain intact. In 2020 thousands of PSB employees will retire
              and new positions will be opened up. Also, private banks such as
              Axis bank,&nbsp;
              <a href="https://ciso.economictimes.indiatimes.com/tag/hdfc+bank">
                HDFC bank
              </a>
              &nbsp;are expanding aggressively and will have new positions to
              fill up. Along with the routine positions, banks are also hiring
              aggressively for data scientists, AI engineers, RPA experts, etc.
            </p>
            <br />
          </Col>
        </Row>
      </Container>
    </>
  );
}

export default news;
