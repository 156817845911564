import React from "react";
import { Container, Row, Col, Button } from "reactstrap";
import {
  FacebookShareButton,
  LinkedinShareButton,
  TwitterShareButton,
} from "react-share";

function news({ news }) {
  const shareUrl = "www.finnosec.com/finnoseclive/";
  console.log(news);
  return (
    <>
      <Container>
        <Row>
          <Col xs={12}>
            <div className="owner">
              <div className="avatar-big text-center">
                <img
                  alt=""
                  className="img-no-padding img-responsive img-rounded pt-2"
                  src={require(`assets/images/news/${
                    news.image ? news.image : "live.jpeg"
                  }`)}
                />
              </div>
            </div>
          </Col>
        </Row>

        <Row>
          <Col className={"ml-auto mr-auto my-2"} md="10">
            <h6>
              Source :{" "}
              <a href="https://www.securitymagazine.com/articles/92456-cyber-resilience-a-new-way-of-looking-at-cybersecurity">
                BNP Media Owner & Co-CEO, Tagg Henderson
              </a>
            </h6>
          </Col>
        </Row>
        <Row>
          <Col xs={1} className="d-none d-xl-block d-lg-block d-md-block"></Col>
          <FacebookShareButton
            url={`${shareUrl}${news.link}`}
            style={{ padding: 0, margin: 0, display: "inline !important" }}
            children={
              <Button
                color="neutral"
                outline
                style={{
                  padding: "0rem",
                  marginRight: "30px",
                  marginLeft: "15px",
                }}
              >
                <span style={{ color: "#3b5998", display: "inline" }}>
                  <i
                    style={{ fontSize: "50px" }}
                    className="fa fa-facebook-square float-left"
                  />
                </span>
              </Button>
            }
          />

          <LinkedinShareButton
            url={`${shareUrl}${news.link}`}
            style={{ padding: 0, margin: 0, display: "inline !important" }}
            children={
              <Button
                color="neutral"
                outline
                style={{
                  padding: "0rem",
                  marginRight: "30px",
                }}
              >
                <span style={{ color: "#0976b4" }}>
                  <i
                    style={{ fontSize: "50px" }}
                    className="fa fa-linkedin-square float-left"
                  />
                </span>
              </Button>
            }
          />
          <TwitterShareButton
            url={`${shareUrl}${news.link}`}
            style={{ padding: 0, margin: 0, display: "inline !important" }}
            children={
              <Button
                color="neutral"
                outline
                style={{
                  padding: "0rem",
                  marginRight: "30px",
                }}
              >
                <span style={{ color: "#55acee" }}>
                  <i
                    style={{ fontSize: "50px" }}
                    className="fa fa-twitter-square float-left"
                  />
                </span>
              </Button>
            }
          />
        </Row>

        <Row>
          <Col className={"ml-auto mr-auto text-justify"} md="10">
            <p>
              The need for real-time visibility of an organization&rsquo;s
              security posture is becoming increasingly evident. If you
              don&rsquo;t know what your current security posture is, you cannot
              know if what worked for your organization yesterday will also work
              today. A new threat intelligence can change an entire defense
              strategy for an organization. Thus, it&rsquo;s important to ensure
              that the cyber defense mechanism is dynamic, real-time and
              on-line.
            </p>
            <br />
            <p>
              The risks of an attack are not new, but as incidents become more
              sophisticated and persistent, organizations need to move from
              cybersecurity to cyber resilience.
            </p>
            <br />
            <p>
              An effective cyber resilience program should include a
              programmatic approach to withstand disruptive cyber incidents. A
              simple way to look at an effective cyber resilience model can be
              to keep in mind the three&nbsp;P&rsquo;s : Predict, Prioritize and
              Practice. We should be able to anticipate a breach rather than
              react to it as not all vulnerabilities require our immediate
              attention and the senior leadership of the organization is in the
              best position to decide what is a priority and what is not.&nbsp;
            </p>
            <br />
            <p>
              And lastly, we should have a governance framework with policies,
              procedures&nbsp;and accountability, integrated into the business
              strategy. This needs to be powered by the right people and
              technology. This should also be revisited on an ongoing basis for
              relevance with respect to emerging threats.
            </p>
            <br />
            <p>
              Today, one of the common mistakes organizations make when
              responding to a breach is not being able to provide timely
              incident data. A systemic lack of actionable data can hinder the
              efforts to anticipate and address cyber risks effectively. This
              indicates there is lack of intelligence in threat intelligence
              within the organization.&nbsp;
            </p>
            <br />
            <p>
              Today, organizations are paying a lot to receive threat intel.
              However, the intelligence they receive today is data they can find
              in their own logs, whereas threat actors are well ahead
              and&nbsp;have already weaponized artificial intelligence (AI)
              to&nbsp;
              <a href="https://securityintelligence.com/deeplocker-how-ai-can-power-a-stealthy-new-breed-of-malware/">
                unleash a new breed of cyberattacks
              </a>
              . A lot of organizations deploy off-the-shelf response procedures,
              which are outdated and ineffective against evolving threats and
              sometimes this slows down their investigations.
            </p>
            <br />
            <p>
              Lack of coordination within key parties involved or wrong-sized
              and&nbsp;mismanaged teams lacking skills to make critical
              decisions is another factor where organizations go wrong. If your
              staff is not receiving regular training and is not being regularly
              tested, your cyber resilience strategy has a major deficiency in
              it.{" "}
            </p>
            <br />
            <p>
              A strong cyber resilient program ensures continuity of operation
              with minimum impact to business despite an incident. It is an
              iterative process providing the means of recovery from an attack.
              Organizations can follow some simple steps to start their journey
              of being cyber resilient.
            </p>
            <br />
            <p>
              First is to main basic hygiene. Measure and know your assets and
              infrastructure well. Remember:&nbsp;what you don&rsquo;t know, you
              cannot protect.
            </p>
            <br />
            <p>
              Secondly, configure key security settings, manage access
              permissions and regularly update software with patches.&nbsp;Then,
              have systems and processes in place to find vulnerabilities before
              attackers and prioritize the most important threats. Threat
              intelligence must be flexible and should allow the user to
              deep-dive inside the threats and better understand their origin,
              the distribution mechanism, the actors, the potential victims, the
              attack vectors, TTPs&nbsp;and the data that is being accessed.
            </p>
            <br />
            <p>
              A threat intelligence program must also&nbsp;inform stakeholders
              in a way that is relevant and actionable to ensure stakeholders
              can enforce policies for better business integrity and continuity.
            </p>
            <br />
            <p>
              One of the key areas to focus on is to ensure the policies are
              engrained in the cyber strategy and driven by the organizations
              people, processes and technology. Security should be a board room
              agenda. IT&nbsp;should enable business to make informed decisions
              on how to manage cyber risk while continuing their growth agenda.
              Most directors or CEOs today realize the consequences on the
              bottom line apart from the damage to reputation caused by a breach
              or an attack.
            </p>
            <br />
            <p>
              Cyber resilience is about being prepared -&nbsp;it&rsquo;s not
              bullet proof!
            </p>
            <br />
          </Col>
        </Row>
      </Container>
    </>
  );
}

export default news;
