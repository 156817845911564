import React from "react";

import { Container, Row, Col } from "reactstrap";

function AwardsPage() {
  const size =
    document.body.clientWidth >= 1024 ||
    document.documentElement.clientWidth >= 1024
      ? true
      : false;
  return (
    <>
      <div
        style={{
          overflow: "hidden",
          background:
            "url(" +
            require(`assets/images/${
              size ? "awardsbackground.jpg" : "awardsbackgroundmobile.jpg"
            }`) +
            ")",
          backgroundRepeat: "no-repeat",
          backgroundSize: `${size ? "cover" : "100% 100%"}`,
        }}
        className="section py-3"
      >
        <Container fluid style={{ background: "rgba(0,0,0,0.2)" }}>
          <Container>
            <Row>
              <Col lg={5} className="mb-3  align-self-center">
                <img
                  src={require(`assets/images/logo/awardslogo.png`)}
                  width="100%"
                  alt="awards"
                />
              </Col>
              <Col lg={7}>
                <h2 className="text-700 text-g">
                  THE REGION’S FIRST AND ONLY FINANCIAL INFRASTURTURE SECURITY
                  EXCELLENCE AWARDS
                </h2>

                <br />
                <br />

                <p className="text-white text-700">
                  In our efforts to continue pushing the envelope of Financial
                  Security Excellence and to honour the leaders who have
                  excelled and set a standard in this domain, we will be hosting
                  the Africa regions’ First & only award ceremony for Financial
                  Security leaders.
                </p>

                <p className="text-white text-700">
                  The awards will highlight the rising power of the Security
                  Professionals, whose role is no longer only functional or
                  geographic in nature, but is now a part of the Digital
                  Transformation strategy.
                </p>
                <br />
                <br />
                <p className="text-white text-700">
                  <b>FinnoSec Awards</b> have been constituted to recognise and
                  appreciate the effort of various individuals, institutions and
                  service providers in the field of financial infrastructure
                  security. We seek to honour the pioneers and visionaries who
                  have transformed the security industry.
                </p>
              </Col>
              <Col lg={12}>
                <br />
                <br />
                <p className="text-white text-700">
                  FinnoSec as a brand resonates and revolves around the best and
                  the next innovations that enable excellence.{" "}
                  <b>FinnoSec Awards</b> cherish and celebrate outstanding
                  achievements in securing the banking and finance industry.
                  They are aimed at honouring organisations and individuals,
                  that have consistently demonstrated exemplary performance.
                  These awards set a standard of excellence that is held in high
                  regard. They aim to serve as an effective platform for
                  financial institutes to showcase their talent and identify
                  leaders within the industry. <b>FinnoSec Awards</b> will not
                  just recognise the endeavours of the most successful banking &
                  financial organisations, but will also set a benchmark,
                  inspiring other organisations to attain their own goals.
                </p>
                <br />
                <br />
              </Col>

              {/* <Col lg={12} className="mt-3" style={{ backgroundColor: 'rgba(0,0,0,0.7)' }}></Col> */}
            </Row>
          </Container>
        </Container>
      </div>
      <div
        style={{
          overflow: "hidden",
        }}
        className="section"
      >
        <Container>
          <h2 className="text-700 text-i">NOMINATION CATEGORIES</h2>
          <Row className="border p-3">
            <Col lg={4} className="px-0">
              <ul>
                {content1.map((c) => (
                  <li key={c}>
                    <p className="text-700">{c}</p>
                  </li>
                ))}
              </ul>
            </Col>
            <Col lg={4} className="px-0">
              <ul>
                {content2.map((c) => (
                  <li key={c}>
                    <p className=" text-700">{c}</p>
                  </li>
                ))}
              </ul>
            </Col>
            <Col lg={4} className="px-0">
              <ul>
                {content3.map((c) => (
                  <li key={c}>
                    <p className=" text-700">{c}</p>
                  </li>
                ))}
              </ul>{" "}
            </Col>
          </Row>
        </Container>
      </div>
    </>
  );
}

export default AwardsPage;

const content1 = [
  "Excellence in Data Analytics",
  "Excellence in Response",
  "Excellence in Biometrics",
  "Excellence in End-Point Security",
  "Excellence in Cards and Payments ",
  "Excellence in Authentication",
  "Excellence in Cyber Security",
  "Excellence in Access Management",
];

const content2 = [
  "Excellence in Cloud Security",
  "Excellence in Artificial Intelligence Security",
  "Excellence in Encryption",
  "Excellence in IoT Security",
  "Excellence in Threat Detection",
  "Excellence in AML Strategy",
  "Excellence in Fraud Prevention",
];

const content3 = [
  "Excellence in Critical Infrastructure Security",
  "FinnoSec Service Provider of the Year",
  "Emerging Security Solution of the Year",
  "CISO of the Year Middle East",
  "CIO of the Year Middle East",
  "CTO of the Year Middle East",
  "FinnoSec Influencer of the Year",
];
