import React from 'react';
import { Container, Row, Col } from 'reactstrap';
import Card from 'reactstrap/lib/Card';

function Event() {
  let pageHeader = React.createRef();

  return (
    <>
      <div
        style={{
          backgroundColor: '#ffffff',
        }}
        className="section py-3"
        data-parallax={true}
        ref={pageHeader}
      >
        <Container>
          <Row className="justify-content-center">
            <Col lg={6} className="p-4 text-center">
              <a href="http://exibex.com" className="">
                <img
                  src={require('assets/images/logo/exibex.gif')}
                  className="mx-auto mb-3"
                  alt="exibex"
                  width="100%"
                  style={{ maxWidth: '270px' }}
                />
              </a>
              <h5 className="text-400 text-justify mt-0">
                As market leaders in knowledge-based conferences, managed events and business
                consulting, Exibex provides innovative and engaging networking platforms to create
                and engage C level communities for global corporations. Our platforms enable
                acceleration of both individual, and organisational performance, to benefit global
                business leaders. We comprehend the challenges organisations face in this volatile
                and uncertain market, so we look to help your core revenue generation teams by
                building a complete 360-degree package tailored to your business strategies, goals
                and needs.
              </h5>
            </Col>
          </Row>
        </Container>
      </div>
      <div
        className="section pt-2"
        style={{
          backgroundColor: '#000000',
          backgroundImage: 'url(' + require('assets/images/graphic23.png') + ')',
          backgroundPosition: 'top right',
          backgroundRepeat: 'no-repeat',
          backgroundSize: 'cover',
        }}
      >
        <Container>
          <Row className="justify-content-center">
            <Col lg={12}>
              <h2 className="text-700 text-g mb-5 text-center">OUR SERIES IS GEARING UP</h2>
            </Col>

            {content.map((c) => (
              <Col lg={2} xs={6} className="mt-2">
                <Card style={{ background: 'transparent' }}>
                  <a target="_blank" href={c.link} rel="noopener noreferrer">
                    <img
                      src={require(`assets/images/logo/${c.image}`)}
                      alt="ea"
                      width="100%"
                      style={{ maxWidth: '270px', padding: '5px' }}
                    />
                  </a>
                </Card>
              </Col>
            ))}
            <Col lg={12}>
              <h3 className="text-700 mb-5 text-center text-g">
                KNOW MORE AT -{' '}
                <a href="http://finnovex.com" className="text-g text-700">
                  WWW.FINNOVEX.COM
                </a>
              </h3>
            </Col>
          </Row>
        </Container>
      </div>
    </>
  );
}

export default Event;

const content = [
  {
    image: '1.png',
    link: 'https://qa.finnovex.com/',
  },
  {
    image: '2.png',
    link: 'https://finnovex.com/',
  },
  {
    image: '3.png',
    link: 'https://ea.finnovex.com/',
  },

  {
    image: '4.png',
    link: 'https://eu.finnovex.com/',
  },
  {
    image: '6.png',
    link: 'https://sa.finnovex.com/',
  },
  {
    image: '7.png',
    link: 'https://me.finnovex.com/',
  },
  {
    image: '5.png',
    link: 'https://finnosec.com/',
  },
  {
    image: '8.png',
    link: 'https://wa.finnovex.com/',
  },
];
