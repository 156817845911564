import React from "react";
import { Container, Row, Col } from "reactstrap";
import ScrollAnimation from "react-animate-on-scroll";
import Card from "reactstrap/lib/Card";

function Spotlight() {
  return (
    <div
      className='section'
      style={{
        backgroundColor: "#fcc200",
      }}
      data-parallax={true}
    >
      <Container>
        <Row style={{}} className='justify-content-center'>
          <Col lg={8} className='p-4'>
            <h1 className='text-700 text-center text-g mt-0'>
              FINNOSEC SPOTLIGHT 2022
            </h1>
          </Col>
        </Row>
        <Row className='text-white  justify-content-center'>
          {spotlights.map((s, i) => (
            <Col key={i} lg={3} xs={6} className="text-center">
              <ScrollAnimation
                animateIn={"fadeInDown"}
                animateOnce={true}
                duration={1.5}
              >
                <img
                  src={require(`assets/images/icons/${s.image}`)}
                  width='100'
                  className='mx-auto'
                  alt='icon'
                />

                <p className='text-700 mt-2 text-dark'>{s.title}</p>
              </ScrollAnimation>
            </Col>
          ))}
        </Row>
      </Container>
    </div>
  );
}

export default Spotlight;

export const spotlights = [
  { title: "Emerging Threat To The Financial Industry", image: "1.png" },
  { title: "Security Standards In Fintech Technology", image: "15.png" },
  {
    title: "Customer Digital-Identity And Data Access Management",
    image: "3.png",
  },
  { title: "IT Governance In Banking And Insurance Sector", image: "4.png" },
  {
    title: "Best Practices In Assessing Third-Party Security And Risk",
    image: "1.png",
  },
  {
    title:
      "Cyber Threat Intelligence: Developing Inter-Disciplinary Threat Intelligence Strategy",
    image: "5.png",
  },
  {
    title:
      "Enhancing Security Strategies To Evolve For A Hybrid Working Future",
    image: "6.png",
  },
  { title: "Data Management And Privacy- A Global Obligation", image: "2.png" },
  {
    title: "Use Of Artificial Intelligence In Security And Risk Management",
    image: "10.png",
  },
  {
    title:
      "Open Infrastructure: Key To Next Generation Solution To Mitigate Risk",
    image: "11.png",
  },
  {
    title:
      "Blockchain A Decentralised Solution For A Data Privacy And Risk Mitigation",
    image: "12.png",
  },
  { title: "Digital Payment’s Security", image: "8.png" },
  {
    title:
      "Cloud Banking: An Important Indicator Of The Shifting Threat Landscape",
    image: "9.png",
  },
  {
    title: "Outlook For Security Talent, Careers, And People",
    image: "15.png",
  },
];
