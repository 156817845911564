import React from "react";
import { Container, Row, Col, Button } from "reactstrap";

function TextGrid() {
  let pageHeader = React.createRef();
  return (
    <>
      <div
        style={{
          backgroundColor: "#fff",
          backgroundImage: "url(" + require("assets/images/bg1.png") + ")",
          backgroundRepeat: "no-repeat",
          backgroundAttachment: "fixed",
          backgroundSize: "cover",
        }}
        className='section py-4'
        data-parallax={true}
        ref={pageHeader}
      >
        <Container>
          <Row>
            <Col lg={7} md={8} sm={12} className='my-auto'>
              <Container className='mt-0'>
                <Row>
                  <Col xs={12}>
                    <h2 className='text-700 text-g'>
                      Don’t second-guess…the Future of Privacy is Now!
                    </h2>
                  </Col>
                </Row>
                <Row>
                  <Col lg={10} xs={12}>
                    <div className='text-white text-300'>
                      <p>
                        <br />
                        Regardless of the global extraordinary dimensions of
                        innovation, banks and financial services are currently
                        responding to what their customers want precisely within
                        the digital era by increasing their investments in
                        digital innovations.
                        <br />
                        <br />
                        In addition, there has been a shift in focus by
                        enhancing banking security and user-experience. Without
                        a doubt these cyber threats and data weaknesses,
                        prominently hinders the progress of digitization, and
                        threaten the benefits delivered through it.
                        Nevertheless, the cybersecurity market in the Middle
                        East & Africa was valued at USD 1903.59 million in 2020,
                        and it is expected to reach USD 2,893.4 million by 2026,
                        while the registering of a CAGR is expected to increase
                        by 7.92% during the forecast period of 2021-2026.
                        <br />
                        <br />
                        This is solely motivated by a global move to
                        progressively increase privacy and data protection
                        legislation. As it relates to the demand by consumers
                        for active protection of their privacy, there has been a
                        widespread effort in strategically promoting national
                        cyber security comprehensively across middle eastern
                        countries.
                        <br />
                        <br />
                        <Button
                          href='/about'
                          className='btn my-2 text-center px-5'
                          color='primary'
                          size='lg'
                          outline
                        >
                          Know More
                        </Button>{" "}
                        {/* <Button
                          href="/register"
                          className="btn my-2 text-center px-5"
                          color="primary"
                          size="lg"
                        >
                          Register
                        </Button> */}
                      </p>
                    </div>
                  </Col>
                </Row>
              </Container>
            </Col>
            <Col lg={4} md={4} sm={12} className='my-auto p-0'></Col>
          </Row>
        </Container>
      </div>
    </>
  );
}

export default TextGrid;
