// import React from "react";
// import {
//   Container,
//   Button,
//   Row,
//   Col,
//   Card,
//   CardImg,
//   CardFooter,
// } from "reactstrap";

// function Price() {
//   //   const size =
//   //     document.body.clientWidth >= 1024 ||
//   //     document.documentElement.clientWidth >= 1024
//   //       ? true
//   //       : false;

//   return (
//     <>
//       <div
//         className="section py-3"
//         style={{
//           background: "#fff",
//         }}
//         data-parallax={true}
//       >
//         <Container>
//           <Row>
//             <Col lg={12} className="text-center mb-5">
//               <h1 className="text-700 text-g text-uppercase">
//                 What’s in store for you? In-Person/ Virtually?
//               </h1>
//               <h2 className="text-100 mt-0 mb-3">
//                 <i> A sync between the physical and virtual experience</i>
//               </h2>
//               {/* <h2 className="text-900 text-center mb-2">

//               </h2>
//               <h5 className="text-400">

//               </h5> */}
//               <h5 className="text-300">
//                 UAE is perceived as safest destinations to host global events
//                 and exhibitions. It also plays host to a rich and diverse
//                 international community. This international community has helped
//                 us make FinnoSec what it is and we understand that while the
//                 fraternity would want to partake in the event, they may not be
//                 able to join us due to travel restrictions. And hence we are
//                 delighted to announce that FinnoSec Middle East 2021 will be a
//                 Hybrid Event. What does that mean?
//               </h5>
//             </Col>
//           </Row>
//           <Row className="justify-content-center">
//             <Col lg={4}>
//               <Card
//                 className="text-center rounded-0"
//                 style={{ minHeight: "320px" }}
//               >
//                 <CardImg
//                   src={require("assets/images/live.jpg")}
//                   className=" rounded-0"
//                 />

//                 <CardFooter>
//                   <Button
//                     href="/hybrid-event"
//                     className="btn"
//                     color="primary"
//                     size="lg"
//                     style={{ width: "90%" }}
//                     outline
//                   >
//                     Learn More
//                   </Button>
//                 </CardFooter>
//               </Card>
//             </Col>
//             <Col lg={4}>
//               <Card
//                 className="text-center rounded-0"
//                 style={{ minHeight: "320px" }}
//               >
//                 <CardImg
//                   src={require("assets/images/online.jpg")}
//                   className=" rounded-0"
//                 />

//                 <CardFooter>
//                   <Button
//                     href="/hybrid-event"
//                     className="btn"
//                     color="primary"
//                     size="lg"
//                     style={{ width: "90%" }}
//                     outline
//                   >
//                     Learn More
//                   </Button>
//                 </CardFooter>
//               </Card>
//             </Col>
//           </Row>
//         </Container>
//       </div>
//     </>
//   );
// }

// export default Price;

import React from "react";
import {
  Container,
  Button,
  Row,
  Col,
  Card,
  CardImg,
  CardFooter,
} from "reactstrap";

function Price() {
  //   const size =
  //     document.body.clientWidth >= 1024 ||
  //     document.documentElement.clientWidth >= 1024
  //       ? true
  //       : false;

  return (
    <>
      <div
        className='section pt-2'
        style={{
          background: "#fff",
        }}
        data-parallax={true}
      >
        <Container className='mt-5'>
          <Row className='justify-content-center'>
            <Col lg={12} className='text-center mb-5'>
              <h1 className='text-700 text-i text-uppercase'>
                What Would you Experience In-Person/ Virtually?
              </h1>

              <br />
              <h5 className='text-400 '>
                As the UAE is perceived as safest destinations to host global
                events and exhibitions, it also plays host to a rich and diverse
                international community. Our international community has helped
                us make the Finnovex Global Series what it is and we understand
                that while Industry Peers would want to partake in the event,
                they may not be able to join us due to travel restrictions. And
                hence we are delighted to announce that Finnosec Middle East
                2022 will be a Hybrid Event.
                <br />
                What does that mean?
              </h5>
            </Col>
            <Col lg={4} className=''>
              <Card
                className='text-center mx-auto'
                style={{ maxWidth: "300px" }}
              >
                <CardImg src={require("assets/images/online.jpg")} />

                <CardFooter>
                  <Button
                    href='/hybrid-event'
                    className='btn'
                    color='primary'
                    size='lg'
                    style={{ width: "99%" }}
                  >
                    <p className='m-0 text-700'>Learn More</p>
                  </Button>
                </CardFooter>
              </Card>
            </Col>
            <Col lg={4} className=''>
              <Card
                className='text-center mx-auto'
                style={{ maxWidth: "300px" }}
              >
                <CardImg src={require("assets/images/live.jpg")} />

                <CardFooter>
                  <Button
                    href='/hybrid-event'
                    className='btn'
                    color='primary'
                    size='lg'
                    style={{ width: "99%" }}
                  >
                    <p className='m-0 text-700'>Learn More</p>
                  </Button>
                </CardFooter>
              </Card>
            </Col>
          </Row>
          <Row className='justify-content-center'></Row>
        </Container>
      </div>
    </>
  );
}

export default Price;
